import React, { useEffect, useState } from 'react';
import { parsePhoneNumber } from 'react-phone-number-input';
import PhoneInput, { Country } from 'react-phone-number-input/input';

import { updateVendorContactInfo } from '@core/services/networkService/_vendors';
import DemmiCard from '@demmi-ui/Card/Card';
import DemmiInputPhone from '@demmi-ui/InputPhone';
import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {
  CSSBlock: string;
  vendor: DemmiFS.Vendor;
  onUpdated: () => void;
}

const parseNumber = (vendor: DemmiFS.Vendor) =>
  typeof vendor.contactNumber === 'string'
    ? parsePhoneNumber(
        vendor.contactNumber,
        vendor.address.countryCode as Country,
      )?.formatInternational()
    : vendor.contactNumber;

const VendorContactInfo: React.FC<Props> = ({ CSSBlock, vendor, onUpdated }) => {
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const [contactNumber, setContactNumber] = useState<string | undefined>(parseNumber(vendor));

  useEffect(() => {
    setIsValid(!!(contactNumber && contactNumber.length > 0));
  }, [contactNumber]);

  const onSave = async () => {
    if (isValid) {
      if (!vendor || !contactNumber) return;

      setIsLoading(true);
      await updateVendorContactInfo(vendor.docID, contactNumber).then(() => onUpdated());

      setIsLoading(false);
      setEditMode(false);
    }
  };

  const onCancel = () => {
    setContactNumber(parseNumber(vendor));
    setEditMode(false);
  };

  const editModeActive = () => {
    return (
      <div className={`${CSSBlock}__content`}>
        <div className={`${CSSBlock}__item`}>
          <div className={`${CSSBlock}__item-key`}>Contact Number</div>
          <div className={`${CSSBlock}__item-value`}>
            <PhoneInput
              inputComponent={DemmiInputPhone}
              country={vendor.address.countryCode as Country}
              value={contactNumber}
              onChange={e => setContactNumber(e?.toString() ?? '')}
            />
          </div>
        </div>
      </div>
    );
  };
  const editModeInactive = () => {
    return (
      <div className={`${CSSBlock}__content`}>
        <div className={`${CSSBlock}__item`}>
          <div className={`${CSSBlock}__item-key`}>Contact Number</div>
          <div className={`${CSSBlock}__item-value`}>{contactNumber}</div>
        </div>
      </div>
    );
  };
  const cardContent = () => (editMode ? editModeActive() : editModeInactive());

  return (
    <DemmiCard
      title="Contact Information"
      isLoading={isLoading}
      editMode={editMode}
      onClickEdit={() => setEditMode(true)}
      onCancel={onCancel}
      onSave={onSave}
      isValid={isValid}
      collapsed>
      {cardContent()}
    </DemmiCard>
  );
};

export default VendorContactInfo;
