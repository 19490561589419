import {
  DocumentData,
  QueryDocumentSnapshot,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from 'firebase/firestore';

import { FSCollections } from '@providers/firestoreProvider';

import { getDemmiDB } from '../../../firebase';
import { FSCollectionNames, FSSubCollectionNames } from './networkService';
import { DemmiFS } from '@subhanhabib/demmilib';

export const getProducts = async (vendorID: string): Promise<DemmiFS.ProductWithRaw[]> => {
  const q = query(FSCollections.Products, where('vendorID', '==', vendorID));
  const querySnapshot = await getDocs(q);
  const products: DemmiFS.ProductWithRaw[] = [];
  querySnapshot.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
    products.push({
      docID: doc.id,
      raw: doc.data(),
      ...(doc.data() as DemmiFS.FSProduct),
      variants: [],
    });
  });
  return products;
};

export const getProduct = async (
  productID: string,
): Promise<DemmiFS.ProductWithRaw | undefined> => {
  const docSnap = await getDoc(doc(getDemmiDB(), FSCollectionNames.PRODUCTS, productID));
  console.log(docSnap.data());
  if (docSnap.exists()) {
    return {
      docID: docSnap.id,
      raw: docSnap.data(),
      ...(docSnap.data() as DemmiFS.FSProduct),
      // createdAt,
      // updatedAt,
      variants: [],
    };
  } else {
    console.log('No such document!');
  }
  return undefined;
};

export const getProductVariants = async (
  productID: string,
): Promise<DemmiFS.ProductVariantWithRaw[]> => {
  const querySnapshot = await getDocs(
    FSCollections.ProductVariants([productID, FSSubCollectionNames.VARIANTS]),
  );
  const variants: DemmiFS.ProductVariantWithRaw[] = [];
  querySnapshot.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
    variants.push({
      docID: doc.id,
      raw: doc.data(),
      productID,
      ...(doc.data() as DemmiFS.FSProductVariant),
    });
  });
  return variants;
};
