import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getCustomer } from '@core/services/networkService/_customer';
import { RouteParams } from '@core/services/routingService/routingService';
import { demmiRequest } from '@helpers/app.helper';
import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {}

const SupportChatView: React.FC<Props> = ({}) => {
  const CSSBlock = 'demmi-support-chat';
  const { [RouteParams.SUPPORT_CHAT_ID]: chatID } = useParams();

  const [chat, setChat] = useState<DemmiFS.DemmiSupport>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    refreshChat();
  }, [chatID]);

  const refreshChat = () => {
    if (chatID) {
      setIsLoading(true);
      demmiRequest(getCustomer(chatID))
        .then(f => {
          // setChat(f);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  };

  return (
    <div className={`${CSSBlock}`}>
      {chat && (
        <>
          <div className={`${CSSBlock}__title`}>{chat.title}</div>
          {/* <RawDetails data={chat.raw} /> */}
        </>
      )}
    </div>
  );
};

export default SupportChatView;
