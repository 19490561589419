import React, { HTMLProps, PropsWithChildren, useState } from 'react';

import ElementLoadingIndicator from '@components/loadingIntro/ElementLoadingIndicator';
import DemmiIconButton from '@demmi-ui/IconButton';

interface Default extends Base {
  titleButtons?: JSX.Element[];
}
interface EditableContent extends Base {
  editMode: boolean;
  onClickEdit: () => void;
  onCancel: () => void;
  onSave: () => void;
  isValid: boolean;
}

interface Base extends PropsWithChildren, HTMLProps<HTMLDivElement> {
  rootClassName?: string;
  buttons?: JSX.Element[];
  title?: string;
  subtitle?: string;
  darkOnly?: boolean;
  isLoading?: boolean;
  collapsed?: boolean;
}

type Props = Default | EditableContent;

const isEditableContent = (props: Props): props is EditableContent => {
  return 'editMode' in props;
};

const DemmiCard: React.FC<Props> = ({
  rootClassName,
  children,
  buttons,
  title,
  subtitle,
  darkOnly,
  isLoading,
  collapsed: startCollapsed = false,
  ...props
}) => {
  const CSSBlock = 'demmi-ui-card';
  const [collapsed, setCollapsed] = useState(startCollapsed);
  const titleButtons = isEditableContent(props)
    ? [
        !props.editMode
          ? [<DemmiIconButton key={'edit'} faIcon="fa-pencil" onClick={props.onClickEdit} />]
          : [
              <DemmiIconButton key={'cancel'} faIcon="fa-cancel" onClick={props.onCancel} />,
              <DemmiIconButton
                key={'save'}
                disabled={!props.isValid}
                faIcon="fa-save"
                onClick={props.onSave}
              />,
            ],
      ]
    : (props as Default).titleButtons;

  return (
    <div
      {...props}
      className={`${CSSBlock} ${darkOnly ? `${CSSBlock}--dark-only` : ''} ${
        rootClassName ? rootClassName : ''
      } ${collapsed ? `${CSSBlock}--collapsed` : ''} ${isLoading ? `${CSSBlock}--loading` : ``}`}>
      {title && (
        <div className={`${CSSBlock}__title`}>
          <span>
            <DemmiIconButton
              key={'edit'}
              className={`${CSSBlock}__button-collapse`}
              variant="transparent"
              faIcon="fa-caret-up"
              onClick={() => setCollapsed(!collapsed)}
            />
            {subtitle ? (
              <span className={`${CSSBlock}__title-with-subtitle`}>
                {title}
                <span className={`${CSSBlock}__subtitle`}>{subtitle}</span>
              </span>
            ) : (
              <>{title}</>
            )}
          </span>
          <span>
            {titleButtons && !collapsed && (
              <div className={`${CSSBlock}__control-buttons`}>{titleButtons}</div>
            )}
          </span>
        </div>
      )}
      <span className={`${CSSBlock}__content ${props.className ? props.className : ``}`}>
        {children}
      </span>
      <ElementLoadingIndicator isLoading={isLoading ?? false} />
    </div>
  );
};

export default DemmiCard;
