import { CollectionReference, DocumentData, collection } from 'firebase/firestore';
import { ReactNode, createContext, useContext } from 'react';

import { FSCollectionNames } from '@core/services/networkService/networkService';
import { FSDemmiAdmin, FSDemmiAdminVendor } from '@models/demmi.model';

import { getDemmiDB } from '../firebase';

import { DemmiFS } from '@subhanhabib/demmilib';

export interface FirestoreProviderProps {
  children?: ReactNode;
}

export interface UserContextState {
  isAuthenticated: boolean;
  isLoading: boolean;
  id?: string;
}

export const UserStateContext = createContext<UserContextState>({} as UserContextState);

export const useUserContext = (): UserContextState => {
  return useContext(UserStateContext);
};

export interface FirestoreContextModel {
  vendorsCollection: CollectionReference<DemmiFS.FSVendor>;
  productsCollection: CollectionReference<DemmiFS.FSProduct>;
  variantsCollection: (pathSegments?: string[]) => CollectionReference<DemmiFS.FSProductVariant>;
  demmiAdminVendorsCollection: (pathSegments?: string[]) => CollectionReference<FSDemmiAdminVendor>;
}

export const FirestoreContext = createContext<FirestoreContextModel>({} as FirestoreContextModel);

export function useFirestore(): FirestoreContextModel {
  return useContext(FirestoreContext);
}

export const createCollection =
  <T = DocumentData,>(collectionName: string) =>
  (pathSegments: string[] = []) => {
    return collection(getDemmiDB(), collectionName, ...pathSegments) as CollectionReference<T>;
  };

export const FSCollections = {
  InviteCodes: createCollection<DemmiFS.FSInviteCode>(FSCollectionNames.INVITE_CODES)(),
  Users: createCollection<DemmiFS.FSUser>(FSCollectionNames.USERS)(),
  // Categories: createCollection<DemmiFS.FSOrder>(FSCollectionNames.CATEGORIES)(),
  Orders: createCollection<DemmiFS.FSOrder>(FSCollectionNames.ORDERS)(),
  Vendors: createCollection<DemmiFS.FSVendor>(FSCollectionNames.VENDORS)(),
  VendorReview: createCollection<DemmiFS.FSVendorReview>(FSCollectionNames.VENDORS),
  // Coupons: createCollection<FSCoupon>(FSCollectionNames.COUPONS)(),
  Products: createCollection<DemmiFS.FSProduct>(FSCollectionNames.PRODUCTS)(),
  ProductVariants: createCollection<DemmiFS.FSProductVariant>(FSCollectionNames.PRODUCTS),
  Demmi: createCollection<FSDemmiAdmin>(FSCollectionNames.DEMMI)(),
  DemmiAdminVendors: createCollection<FSDemmiAdminVendor>(FSCollectionNames.DEMMI),
  DemmiSupport: createCollection<DemmiFS.FSDemmiSupport>(FSCollectionNames.DEMMI_SUPPORT)(),
  DemmiSupportMessages: createCollection<DemmiFS.FSDemmiSupportMessage>(
    FSCollectionNames.DEMMI_SUPPORT,
  ),
  NotificationsVendor: createCollection<DemmiFS.FSNotificationVendor>(
    FSCollectionNames.NOTIFICATIONS_VENDOR,
  )(),
  NotificationsCustomer: createCollection<DemmiFS.FSNotificationCustomer>(
    FSCollectionNames.NOTIFICATIONS_CUSTOMER,
  )(),
};

export const FirestoreProvider = ({ children }: FirestoreProviderProps): JSX.Element => {
  const values = {
    usersCollection: FSCollections.Users,
    vendorsCollection: FSCollections.Vendors,
    productsCollection: FSCollections.Products,
    variantsCollection: FSCollections.ProductVariants,
    demmiColleciton: FSCollections.Demmi,
    demmiAdminVendorsCollection: FSCollections.DemmiAdminVendors,
  };

  return <FirestoreContext.Provider value={values}>{children}</FirestoreContext.Provider>;
};
