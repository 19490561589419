import React, { useState } from 'react';

import DemmiCard from '@demmi-ui/Card/Card';

import GeoLocationMap from '../../../../components/geoLocationMap/GeoLocationMap';
import DemmiButton from '@demmi-ui/Button';
import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {
  CSSBlock: string;
  isLoading: boolean;
  vendor: DemmiFS.Vendor;
  onSave: (address: DemmiFS.DemmiAddress) => void;
}

const VendorAddressGeo: React.FC<Props> = ({ CSSBlock, isLoading, vendor, onSave }) => {
  const [address, setAddress] = useState<DemmiFS.DemmiAddress | undefined>(vendor.address);

  const onCancel = () => setAddress(vendor.address);
  const addressDefinitions = DemmiFS.DemmiAddressHelper.getFieldDefinitions(
    vendor.address.countryCode,
  );

  return (
    <DemmiCard title="Geo" isLoading={isLoading}>
      <div className={`${CSSBlock}__geo`}>
        <GeoLocationMap countryCode={vendor.address.countryCode} setAddress={setAddress} />
        <div className={`${CSSBlock}__address-preview`}>
          {Object.entries(addressDefinitions!).map(([key, value]) => (
            <div className={`${CSSBlock}__details-item`}>
              <div className={`${CSSBlock}__details-key`}>{value.label}</div>
              <div className={`${CSSBlock}__details-value`}>
                {DemmiFS.DemmiAddressHelper.getFieldValue(key, address!)}
              </div>
            </div>
          ))}
        </div>
        <div className={`${CSSBlock}__buttons-row`}>
          <DemmiButton text="Cancel" onClick={onCancel} />
          <DemmiButton
            disabled={!address}
            text="Save"
            onClick={() => {
              console.log(address);
              if (address) onSave(address);
            }}
          />
        </div>
      </div>
    </DemmiCard>
  );
};

export default VendorAddressGeo;
