export enum FSCollectionNames {
  PRODUCTS = 'products',
  USERS = 'users',
  ORDERS = 'orders',
  COUPONS = 'coupons',
  DEMMI = 'demmi',
  CATEGORIES = 'categories',
  INVITE_CODES = 'invite_codes',
  DEMMI_SUPPORT = 'demmi_support',
  NOTIFICATIONS_VENDOR = 'notifications_vendor',
  NOTIFICATIONS_CUSTOMER = 'notifications_customer',
  VENDORS = 'vendors',
  VENDOR_USER = 'vendor_user',
}

export enum FSSubCollectionNames {
  VARIANTS = 'variants',
  REVIEWS = 'reviews',
  DEMMI_SUPPORT_MESSAGES = 'messages',
  DEMMI_ADMIN_VENDORS = 'vendors',
  DEMMI_ADMIN_ADMINUSERS = 'admin_users',
}

export enum FSConstantDocNames {
  DEMMI_ADMIN = 'admin',
  DEMMI_CONSTANTS = 'constants',
  DEMMI_LAUNCH = 'launch',
}

export enum FSDocNames {
  DEMMI_CONSTANTS = 'constants',
  DEMMI_LAUNCH = 'launch',
}
