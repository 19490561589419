import React from 'react';

interface Props {
  error?: string;
}

const LoginError: React.FC<Props> = ({ error }) => {
  const CSSBlock = 'login-error';

  return (
    <div className={`${CSSBlock} ${error ? `${CSSBlock}--visible` : ''}`}>
      <div className={`${CSSBlock}__error`}>{error ?? ''}</div>
    </div>
  );
};

export default LoginError;
