import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '@core/hooks/appHooks';
// import {
//   RoutingViews,
//   SearchParams,
//   routingPaths,
// } from '@core/services/routingService';
import { navigateTransitioned } from '@helpers/app.helper';
// import { OrdersPageTabs } from '@pages/orders/orderItem/OrderItem';

import NotificationItem from './_notificationItem';
import { DemmiFS } from '@subhanhabib/demmilib';
import { NotificationCustomerWithVendor } from '@models/notifications.model';

interface Props {
  notification: NotificationCustomerWithVendor;
  onNavigate: () => void;
}

const NotificationCustomerRefundRequest: React.FC<Props> = ({ notification, onNavigate }) => {
  const CSSBlock = 'notification-refund-request';
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onClickView = () => {
    // navigateTransitioned({
    //   dispatch,
    //   navigate,
    //   to: routingPaths[RoutingViews.ORDERS_ALL],
    //   search: {
    //     [SearchParams.ORDER_ID]: notification.orderDocID!,
    //     [SearchParams.ACTIVE_TAB]: OrdersPageTabs.REFUND_STATUS,
    //   },
    // });
    // onNavigate();
  };

  return (
    <NotificationItem
      notification={notification}
      CSSBlock={CSSBlock}
      buttonText="View"
      buttonOnClick={onClickView}></NotificationItem>
  );
};

export default NotificationCustomerRefundRequest;
