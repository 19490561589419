import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import AsyncImage from '@components/asyncImage/AsyncImage';
import Sidebar from '@components/sidebar/Sidebar';
import { getProducts } from '@core/services/networkService/_product';
import {
  RouteParams,
  RoutingViews,
  getRouteFromPath,
  getViewFromPath,
} from '@core/services/routingService/routingService';
import DemmiButtonSwitch from '@demmi-ui/ButtonSwitch';
import { demmiRequest } from '@helpers/app.helper';
import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {}

const VendorInventory: React.FC<Props> = ({}) => {
  const CSSBlock = 'vendor-inventory';
  const { [RouteParams.VENDOR_ID]: vendorID, [RouteParams.PRODUCT_ID]: productID } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [products, setProducts] = useState<DemmiFS.Product[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<DemmiFS.Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<DemmiFS.Product | undefined>();

  useEffect(() => {
    const product = products.find(p => p.docID === productID);
    console.log(product);
    if (productID) {
      setSelectedProduct(undefined);
      setTimeout(() => {
        if (productID) setSelectedProduct(product);
      }, 50);
    }
  }, [productID, products]);

  const tabs = [
    { label: 'Raw', route: RoutingViews.VENDORS_INVENTORY_RAW },
    { label: 'Details', route: RoutingViews.VENDORS_INVENTORY_DETAILS },
    { label: 'Variants', route: RoutingViews.VENDORS_INVENTORY_VARIANTS },
  ];
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const view = getViewFromPath(location.pathname);
    console.log(view);
    setActiveTab(
      view === RoutingViews.VENDORS_INVENTORY_RAW
        ? 0
        : view === RoutingViews.VENDORS_INVENTORY_DETAILS
          ? 1
          : view === RoutingViews.VENDORS_INVENTORY_VARIANTS
            ? 2
            : -1,
    );
  }, [location]);

  useEffect(() => onClickRefresh(), []);
  useEffect(() => setFilteredProducts(products), [products]);

  const onClickTab = (index: number, view: RoutingViews) => {
    setActiveTab(index);
    navigate(
      getRouteFromPath(view, {
        [RouteParams.VENDOR_ID]: vendorID,
        [RouteParams.PRODUCT_ID]: productID,
      }),
      {
        replace: true,
      },
    );
  };

  const onClickProduct = (index: number) => {
    const product = products[index];
    setSelectedProduct(undefined);
    navigate(
      getRouteFromPath(RoutingViews.VENDORS_INVENTORY_RAW, {
        [RouteParams.VENDOR_ID]: vendorID,
        [RouteParams.PRODUCT_ID]: product.docID,
      }),
      {
        replace: true,
      },
    );
    setTimeout(() => {
      if (product) setSelectedProduct(product);
    }, 50);
  };

  const filterProducts = (filter: string) => {
    const filtered = products.reduce((acc: DemmiFS.Product[], cur: DemmiFS.Product) => {
      if (filter && !cur.name.toLowerCase().includes(filter.toLowerCase())) return acc;
      return [...acc, cur];
    }, []);
    setFilteredProducts(filtered);
  };

  const onClickRefresh = () => {
    if (vendorID) demmiRequest(getProducts(vendorID)).then(f => setProducts(f));
    setSelectedProduct(undefined);
  };

  const sidebarButtons = () => {
    return filteredProducts.map(product => (
      <div className={`${CSSBlock}__sidebar-button`}>
        <span className={`${CSSBlock}__sidebar-button-image`}>
          <AsyncImage vendorID={product.vendorID} src={product.images[0].thumbnail} />
        </span>
        <span className={`${CSSBlock}__sidebar-button-title`}>{product.name}</span>
        <span className={`${CSSBlock}__sidebar-button-text`}>
          <span className={`${CSSBlock}__sidebar-button-variants`}>3 Variants</span>
          <span className={`${CSSBlock}__sidebar-button-date`}>
            {product.updatedAt
              ? DemmiFS.getDateFromFirestore(product.updatedAt)?.toLocaleString()
              : '-'}
          </span>
        </span>
      </div>
    ));
  };

  return (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__sidebar`}>
        <Sidebar
          fixed
          customButtons={sidebarButtons()}
          active={products.findIndex(f => selectedProduct?.docID === f.docID)}
          title="Products"
          onClick={onClickProduct}
          onSearch={filterProducts}
          searchPlaceholder="Filter Products"
        />
      </div>
      {selectedProduct && (
        <div className={`${CSSBlock}__content`}>
          <div className={`${CSSBlock}__tabs`}>
            <DemmiButtonSwitch
              buttons={tabs.map(t => t.label)}
              index={activeTab}
              onClick={i => onClickTab(i, tabs[i].route)}
            />
          </div>
          <div className={`${CSSBlock}__content-inner`}>
            <Outlet />
          </div>
        </div>
      )}
    </div>
  );
};

export default VendorInventory;
