import React, { useEffect, useState } from 'react';

import DemmiCard from '@demmi-ui/Card/Card';
import DemmiInput from '@demmi-ui/Input';

import { DemmiFS, DemmiHelpers } from '@subhanhabib/demmilib';

interface Props {
  CSSBlock: string;
  isLoading: boolean;
  vendor: DemmiFS.Vendor;
  onSave: (address: DemmiFS.DemmiAddress) => void;
}

const VendorAddressInputs: React.FC<Props> = ({ CSSBlock, isLoading, vendor, onSave }) => {
  const [editMode, setEditMode] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [address, setAddress] = useState<DemmiFS.DemmiAddress>(vendor.address);
  const addressDefinitions = DemmiFS.DemmiAddressHelper.getFieldDefinitions(
    vendor.address.countryCode,
  );

  useEffect(() => {
    setIsValid(!!address && DemmiHelpers.Vendor.isAddressValid(address));
  }, [address]);

  useEffect(() => {
    setAddress(vendor.address);
  }, [vendor]);

  const onCancel = () => {
    setAddress(vendor.address);
    setEditMode(false);
  };

  const updateAddressField = (fieldKey: string, value: string) => {
    if (address) {
      const updatedAddress: DemmiFS.DemmiAddress = {
        ...address,
        address: {
          ...DemmiFS.DemmiAddressHelper.getEmptyDemmiAddress(address.countryCode),
          ...address?.address,
          [fieldKey]: value,
        },
      };

      setAddress(updatedAddress);
    }
  };

  const editModeActive = () => {
    return (
      <div className={`${CSSBlock}__content`}>
        {Object.entries(addressDefinitions!).map(([key, value], i) => (
          <div className={`${CSSBlock}__item`} key={i}>
            <div className={`${CSSBlock}__item-key`}>{value.label}</div>
            <DemmiInput
              type="text"
              value={DemmiFS.DemmiAddressHelper.getFieldValue(key, address!)}
              placeholder={value.hint}
              maxLength={value.maxLength}
              onChangeValue={v => updateAddressField(key, v)}
            />
          </div>
        ))}
      </div>
    );
  };
  const editModeInactive = () => {
    return (
      <div className={`${CSSBlock}__content`}>
        {Object.entries(addressDefinitions!).map(([key, value], i) => (
          <div className={`${CSSBlock}__item`} key={i}>
            <div className={`${CSSBlock}__item-key`}>{value.label}</div>
            <div className={`${CSSBlock}__item-value`}>
              {DemmiFS.DemmiAddressHelper.getFieldValue(key, address!)}
            </div>
          </div>
        ))}
      </div>
    );
  };
  const cardContent = () => (editMode ? editModeActive() : editModeInactive());

  return (
    <DemmiCard
      title="Address"
      isLoading={isLoading}
      editMode={editMode}
      onClickEdit={() => setEditMode(true)}
      onCancel={onCancel}
      onSave={() => {
        if (address) onSave(address);
      }}
      isValid={isValid}>
      {cardContent()}
    </DemmiCard>
  );
};

export default VendorAddressInputs;
