import { QueryDocumentSnapshot } from 'firebase/firestore';

import { DemmiFS } from '@subhanhabib/demmilib';

export const parseToDemmiSupportChat = (
  doc: QueryDocumentSnapshot<DemmiFS.FSDemmiSupport>,
  vendor?: DemmiFS.Vendor,
): DemmiFS.DemmiSupport => {
  return {
    docID: doc.id,
    ...(doc.data() as DemmiFS.FSDemmiSupport),
    vendor,
  };
};

export const parseToDemmiSupportMessage = (
  doc: QueryDocumentSnapshot<DemmiFS.FSDemmiSupportMessage>,
): DemmiFS.DemmiSupportMessage => {
  return {
    docID: doc.id,
    ...(doc.data() as DemmiFS.FSDemmiSupportMessage),
  };
};
