import React from 'react';

import Logo from '@assets/demmi-logo.svg';
import DemmiCard from '@demmi-ui/Card/Card';
import DemmiInput from '@demmi-ui/Input';

interface Props {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  onSubmit: () => void;
}

const CardAuthentication: React.FC<Props> = ({
  email,
  setEmail,
  password,
  setPassword,
  onSubmit,
}) => {
  const CSSBlock = 'card-authentication';

  return (
    <div className={`${CSSBlock}__wrapper`}>
      <div className={`${CSSBlock}`}>
        <div className={`${CSSBlock}__title`}>
          <img src={Logo} />
          <span>Admin Portal</span>
        </div>

        <div className={`${CSSBlock}__inputs-area`}>
          <div className={`${CSSBlock}__inputs`}>
            <DemmiInput
              type="text"
              value={email}
              onChangeValue={setEmail}
              placeholder="Email"
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') onSubmit();
              }}
            />
            <DemmiInput
              type="password"
              value={password}
              onChangeValue={setPassword}
              placeholder="Password"
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') onSubmit();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardAuthentication;
