import {
  QueryDocumentSnapshot,
  Unsubscribe,
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
} from 'firebase/firestore';

import { FSCollections } from '@providers/firestoreProvider';
import { DemmiFS, DemmiLogType, Logger } from '@subhanhabib/demmilib';

import { parseToNotificationCustomer } from './_helper';
import { notificationCustomerQuery, notificationsCustomerQuery } from './_queries';
import { getVendor } from '../_vendors';
import { NotificationCustomerWithVendor } from '@models/notifications.model';

export const listenToNotificationsCustomer = async (
  callback: (notifications: NotificationCustomerWithVendor[]) => void,
): Promise<Unsubscribe> => {
  return onSnapshot(notificationsCustomerQuery(), async querySnapshot => {
    const customerRequests: Promise<{
      notID: string;
      data: DemmiFS.Vendor | undefined;
    }>[] = querySnapshot.docs.map(not => {
      return new Promise((resolve, reject) => {
        not.data().vendorID
          ? getVendor(not.data().vendorID!)
              .then(data => resolve({ notID: not.id, data }))
              .catch(error => reject(error))
          : null;
      });
    });
    const vendors = await Promise.all(customerRequests);

    let notifications: NotificationCustomerWithVendor[] = [];
    querySnapshot.forEach((doc: QueryDocumentSnapshot<DemmiFS.FSNotificationCustomer>) => {
      notifications.push(
        parseToNotificationCustomer(doc, vendors.find(({ notID }) => notID === doc.id)?.data),
      );
    });
    console.log(notifications);
    callback(notifications);
  });
};

export const updateNotificationReadStatus = async (
  notificationID: string,
  read: boolean,
): Promise<void> => {
  const notification = await getDoc(notificationCustomerQuery(notificationID));
  if (!notification) {
    Logger(
      {
        messages: ['Failed to find notification to push read update.'],
        objs: { notificationID, read },
        type: DemmiLogType.error,
      },
      updateNotificationReadStatus,
    );
    return;
  }

  const docRef = doc(FSCollections.NotificationsCustomer, notificationID);
  return updateDoc(docRef, {
    read: read,
  });
};
export const updateNotificationDismissedStatus = async (
  notificationID: string,
  dismissed: boolean,
): Promise<void> => {
  const notification = await getDoc(notificationCustomerQuery(notificationID));
  if (!notification) {
    Logger(
      {
        messages: ['Failed to find notification to push dismissed update.'],
        objs: { notificationID, dismissed },
        type: DemmiLogType.error,
      },
      updateNotificationDismissedStatus,
    );
    return;
  }

  const docRef = doc(FSCollections.NotificationsCustomer, notificationID);
  return updateDoc(docRef, {
    dismissed: dismissed,
  });
};
