import React from 'react';

import DemmiItemSwitch from './ItemSwitch';

interface SelfManaged extends Base {
  index: number;
}

interface Managed extends Base {
  initialIndex?: number;
}

interface Base {
  buttons: string[];
  onClick: (i: number) => Promise<boolean> | void;
}

type Props = Managed | SelfManaged;

const DemmiButtonSwitch: React.FC<Props> = props => {
  const CSSBlock = 'demmi-ui-button-switch';
  const { buttons, onClick, ...otherProps } = props;

  const items = buttons.map((button, i) => (
    <div className={`${CSSBlock}__item`} key={i}>
      <span className={`${CSSBlock}__item-text`}>{button}</span>
    </div>
  ));

  return (
    <div className={`${CSSBlock}`}>
      <DemmiItemSwitch items={items} onClick={onClick} {...otherProps} />
    </div>
  );
};

export default DemmiButtonSwitch;
