import { User } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

import { FSDemmiAdmin } from '@models/demmi.model';
import { FSCollections } from '@providers/firestoreProvider';

import { FSConstantDocNames } from './networkService';

export const getAdminLogin = async (user: User): Promise<boolean> => {
  const docRef = await doc(FSCollections.Demmi, FSConstantDocNames.DEMMI_ADMIN);
  const snap = await getDoc(docRef);

  if (
    snap.exists() &&
    snap.data() &&
    (snap.data() as FSDemmiAdmin).adminUsers &&
    (snap.data() as FSDemmiAdmin).adminUsers.includes(user.uid)
  ) {
    return Promise.resolve(true);
  } else {
    const message = 'User is not an admin.';
    console.log(message);
    return Promise.reject(message);
  }
};
