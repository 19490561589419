import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getCustomer } from '@core/services/networkService/_customer';
import { RouteParams } from '@core/services/routingService/routingService';
import { demmiRequest } from '@helpers/app.helper';

import CustomerCardBasicInfo from './partials/_cardBasicInfo';
import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {}

const CustomerDetails: React.FC<Props> = ({}) => {
  const CSSBlock = 'customers-view';
  const { [RouteParams.CUSTOMER_ID]: customerID } = useParams();

  const [customer, setCustomer] = useState<DemmiFS.User>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    refreshCustomer();
  }, [customerID]);

  const refreshCustomer = () => {
    if (customerID) {
      setIsLoading(true);
      demmiRequest(getCustomer(customerID))
        .then(f => {
          setCustomer(f);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  };

  return (
    <div className={`${CSSBlock}--details`}>
      {customer && (
        <>
          <CustomerCardBasicInfo
            CSSBlock={CSSBlock}
            customer={customer}
            onUpdated={refreshCustomer}
          />

          {/* <CustomerCardAddress
            CSSBlock={CSSBlock}
            customer={customer}
            onUpdated={refreshCustomer}
          /> */}
        </>
      )}
    </div>
  );
};

export default CustomerDetails;
