import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getOrder } from '@core/services/networkService/_orders';
import { RouteParams } from '@core/services/routingService/routingService';
import { demmiRequest } from '@helpers/app.helper';
import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {}

const OrderDetails: React.FC<Props> = ({}) => {
  const CSSBlock = 'order-details';
  const { [RouteParams.ORDER_ID]: orderID } = useParams();

  const [order, setOrder] = useState<DemmiFS.OrderWithRaw>();
  const [isLoading, setIsLoading] = useState(false);
  console.log(order, orderID);

  useEffect(() => {
    refreshOrder();
  }, [orderID]);

  const refreshOrder = () => {
    if (orderID) {
      setIsLoading(true);
      demmiRequest(getOrder(orderID))
        .then(f => {
          setOrder(f);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  };

  return (
    <div className={`${CSSBlock}`}>
      {order && (
        <>
          <div className={`${CSSBlock}__title`}>Order Details</div>
        </>
      )}
    </div>
  );
};

export default OrderDetails;
