import React, { useEffect, useRef, useState } from 'react';

import useDebounce from '@core/hooks/useDebounce';
import { createInviteCode, isEmailAvailable } from '@core/services/networkService/_inviteCodes';
import DemmiButton from '@demmi-ui/Button';
import DemmiIconButton, { IconButtonSize } from '@demmi-ui/IconButton';
import DemmiModalButtonArea from '@demmi-ui/Modal/ModalButtonArea';
import DemmiModalCore, { DemmiModalCoreRef } from '@demmi-ui/Modal/ModalCore';
import { demmiRequest } from '@helpers/app.helper';

import ModalCreateInviteCodeContent from './ModalCreateInviteCodeContent';
import { DemmiHelpers } from '@subhanhabib/demmilib';
import { Timestamp } from 'firebase/firestore';

interface Props {
  onClose: () => void;
  onConfirm: () => void;
}

const ModalCreateInviteCode: React.FC<Props> = ({ onClose, onConfirm }) => {
  const CSSBlock = 'modal-create-invite-code';
  const modalRef = useRef<DemmiModalCoreRef>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState<string>();

  const debouncedInput = useDebounce(email, 500);

  /**
   * 0 = Doing nothing
   * 1 = Checking email in use
   * 2 = Email available
   * 3 = Creating code
   * -1 = Email already in use
   * -2 = Failed to update
   */
  const [state, setState] = useState(0);
  // const [isValid, setIsValid] = useState(false);

  const checkEmail = () => {
    setState(1);
    isEmailAvailable(email).then(b => {
      if (b) {
        setState(2);
        setCode(DemmiHelpers.InviteCodes.generate(email));
      } else {
        setState(-1);
        setCode(undefined);
      }
    });
  };

  useEffect(() => {
    if (email.length > 0 && DemmiHelpers.InviteCodes.isValidEmail(email)) checkEmail();
    else setCode(undefined);
  }, [debouncedInput]);

  const gracefulExit = (postExit: () => void) => {
    modalRef.current ? modalRef.current.gracefulClose(postExit) : postExit();
    setTimeout(() => {
      setIsOpen(false);
      setState(0);
    }, 500);
  };

  const pushCode = () => {
    if (code && state === 2) {
      demmiRequest(
        createInviteCode({
          code: code.replaceAll('-', ''),
          email,
          createdAt: Timestamp.now(),
          sourceCustomerID: 'admin',
          activated: false,
          revoked: false,
        }),
      )
        .catch(() => setState(-1))
        .then(_ => gracefulExit(() => onConfirm()));
    }
  };

  const buttons = [
    <DemmiButton key={0} text="Cancel" outlined onClick={() => gracefulExit(onClose)} />,
    <DemmiButton key={1} text="Create Code" disabled={state !== 2} onClick={pushCode} />,
  ];

  return (
    <>
      <DemmiIconButton
        faIcon="fa-plus"
        onClick={() => setIsOpen(true)}
        size={IconButtonSize.SMALL}
      />
      <DemmiModalCore
        ref={modalRef}
        isOpen={isOpen}
        onExited={() => {
          setIsOpen(false);
          onClose();
        }}
        contentClassName={CSSBlock}
        allowBackdropClose={false}>
        <ModalCreateInviteCodeContent
          CSSBlock={CSSBlock}
          state={state}
          code={code}
          email={email}
          setEmail={setEmail}
        />
        <DemmiModalButtonArea buttons={buttons} />
      </DemmiModalCore>
    </>
  );
};

export default ModalCreateInviteCode;
