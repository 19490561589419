import {
  DocumentData,
  QueryDocumentSnapshot,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from 'firebase/firestore';

import { FSCollections } from '@providers/firestoreProvider';

import { FSCollectionNames, FSSubCollectionNames } from './networkService';

import { getDemmiDB } from '../../../firebase';

import { DemmiFS } from '@subhanhabib/demmilib';
import { getCustomer } from './_customer';

export const getOrders = async (): Promise<DemmiFS.OrderWithRaw[]> => {
  const q = query(FSCollections.Orders);
  const querySnapshot = await getDocs(q);
  let orders: DemmiFS.OrderWithRaw[] = [];

  if (!querySnapshot.empty) {
    const customerRequests: Promise<{
      orderID: string;
      data: DemmiFS.User | undefined;
    }>[] = querySnapshot.docs.map(order => {
      return new Promise((resolve, reject) => {
        getCustomer(order.data().customerID)
          .then(data => resolve({ orderID: order.id, data }))
          .catch(error => reject(error));
      });
    });
    const customers = await Promise.all(customerRequests);
    querySnapshot.forEach(async (doc: QueryDocumentSnapshot<DemmiFS.FSOrder>) => {
      const customer = customers.find(({ orderID }) => orderID === doc.id)?.data;

      const vendors = doc.data()['vendors'];
      orders.push({
        docID: doc.id,
        ...(doc.data() as DemmiFS.FSOrder),
        // orderDate: date!,
        vendors: vendors,
        raw: doc.data(),
        customer,
      });
    });
  } else {
  }

  return orders;
};

export const getOrder = async (orderDocID: string): Promise<DemmiFS.OrderWithRaw | undefined> => {
  const docSnap = await getDoc(doc(getDemmiDB(), FSCollectionNames.ORDERS, orderDocID));

  if (docSnap.exists()) {
    return {
      ...(docSnap.data() as DemmiFS.FSOrder),
      docID: docSnap.id,
      raw: docSnap.data(),
    };
  } else console.log('No such document!');
  return undefined;
};

export const getOrderReview = async (
  vendorID: string,
  orderID: string,
): Promise<DemmiFS.VendorReviewWithRaw | undefined> => {
  const q = query(
    FSCollections.VendorReview([vendorID, FSSubCollectionNames.REVIEWS]),
    where('orderID', '==', orderID),
  );
  const snap = await getDocs(q);
  if (snap.empty) return undefined;

  const customer = await getCustomer(snap.docs[0].data().customerID);
  if (!customer) return undefined;

  const review: DemmiFS.VendorReviewWithRaw = {
    docID: snap.docs[0].id,
    ...snap.docs[0].data(),
    vendorID,
    customer,
    raw: snap.docs[0].data(),
  };
  return review;
};
