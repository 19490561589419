import { doc, orderBy, query, where } from 'firebase/firestore';

import { FSCollections } from '@providers/firestoreProvider';

export const notificationsVendorQuery = () =>
  query(
    FSCollections.NotificationsVendor,
    where('dismissed', '==', false),
    orderBy('timestamp', 'desc'),
  );

export const notificationsCustomerQuery = () =>
  query(
    FSCollections.NotificationsCustomer,
    where('dismissed', '==', false),
    orderBy('timestamp', 'desc'),
  );

// export const notificationsIncDismissedQuery = () =>
//   query(
//     FSCollections.NotificationsVendor,
//     orderBy('timestamp', 'desc'),
// );

export const notificationVendorQuery = (notificationID: string) =>
  doc(FSCollections.NotificationsVendor, notificationID);
export const notificationCustomerQuery = (notificationID: string) =>
  doc(FSCollections.NotificationsCustomer, notificationID);
