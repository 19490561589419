import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import RawDetails from '@components/rawDetails/RawDetails';
import { getCustomer } from '@core/services/networkService/_customer';
import { RouteParams } from '@core/services/routingService/routingService';
import { demmiRequest } from '@helpers/app.helper';
import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {}

const CustomerRaw: React.FC<Props> = ({}) => {
  const { [RouteParams.CUSTOMER_ID]: customerID } = useParams();

  const [customer, setCustomer] = useState<DemmiFS.UserWithRaw>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    refreshCustomer();
  }, [customerID]);

  const refreshCustomer = () => {
    if (customerID) {
      setIsLoading(true);
      demmiRequest(getCustomer(customerID))
        .then(f => {
          setCustomer(f);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  };

  return customer ? <RawDetails data={customer.raw} /> : <></>;
};

export default CustomerRaw;
