import React from 'react';

import DemmiButton from '@demmi-ui/Button';
import DemmiCard from '@demmi-ui/Card/Card';
import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {
  code: DemmiFS.InviteCode;
}

const InviteCodeView: React.FC<Props> = ({ code }) => {
  const CSSBlock = 'invite-codes-view';

  return (
    <div className={`${CSSBlock}`}>
      <DemmiCard rootClassName={`${CSSBlock}__card`}>
        <div className={`${CSSBlock}__content`}>
          <div className={`${CSSBlock}__code`}>{`${code.code.substring(0, 3)}-${code.code.substring(
            3,
          )}`}</div>
          <div className={`${CSSBlock}__email`}>{code.email}</div>
        </div>
        <div className={`${CSSBlock}__buttons`}>
          <DemmiButton text="Send Invite Email" faIcon="fa-paper-plane-top" onClick={() => {}} />
        </div>
      </DemmiCard>
    </div>
  );
};

export default InviteCodeView;
