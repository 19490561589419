import { faStyles } from '@demmi-ui/IconButton';
import React from 'react';

export interface PortalSidebarButtonProps {
  text: string;
  active?: boolean;
  faIcon?: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

const PortalSidebarButton = React.forwardRef<HTMLButtonElement, PortalSidebarButtonProps>(
  ({ text, active, faIcon, disabled, onClick }, ref) => {
    const CSSBlock = 'portal-sidebar-button';

    return (
      <button
        className={`${CSSBlock} ${active ? `${CSSBlock}--active` : ``}`}
        disabled={disabled}
        onClick={onClick}
        ref={ref}>
        {faIcon && (
          <span className={`${CSSBlock}__icon`}>
            <i
              className={
                faStyles.find(item => faIcon.includes(item)) ? faIcon : `fa-solid ${faIcon}`
              }></i>
          </span>
        )}
        <span className={`${CSSBlock}__text`}>{text}</span>
      </button>
    );
  },
);

export default PortalSidebarButton;
