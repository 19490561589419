import React, { useEffect, useState } from 'react';
import { parsePhoneNumber } from 'react-phone-number-input';
import PhoneInput, { Country } from 'react-phone-number-input/input';

import DemmiCard from '@demmi-ui/Card/Card';
import DemmiInputPhone from '@demmi-ui/InputPhone';

import { updateCustomerBasicInfo } from '@core/services/networkService/_customer';
import DemmiInput from '@demmi-ui/Input';
import Menu from '@demmi-ui/Menu/Menu';
import MenuTriggerButton from '@demmi-ui/Menu/MenuTriggerButton';
import { MenuItem } from '@demmi-ui/Menu/MenuTypes';
import DemmiButtonSwitch from '@demmi-ui/ButtonSwitch';
import { DemmiFS, DemmiHelpers } from '@subhanhabib/demmilib';

const isBasicInfoValid = ({
  countryCode,
  title,
  firstName,
  lastName,
  nickname,
  email,
  contactNumber,
  gender,
}: {
  countryCode: string;
  title?: string;
  firstName?: string;
  lastName?: string;
  nickname?: string;
  email?: string;
  contactNumber?: string;
  gender?: number;
}) => {
  return (
    DemmiHelpers.Customer.isContactNumberValid(contactNumber, countryCode) &&
    DemmiHelpers.Customer.isTitleValid(title) &&
    DemmiHelpers.Customer.isFirstNameValid(firstName) &&
    DemmiHelpers.Customer.isLasttNameValid(lastName) &&
    DemmiHelpers.Customer.isNicknameValid(nickname) &&
    DemmiHelpers.Customer.isEmailValid(email) &&
    DemmiHelpers.Customer.isGenderValid(gender)
  );
};

const getMenuContent = () => {
  return {
    sections: [
      {
        items: DemmiHelpers.Customer.customerTitles.map(
          t =>
            ({
              id: t,
              text: t,
              item: t,
            }) as MenuItem<string>,
        ),
      },
    ],
  };
};

interface Props {
  CSSBlock: string;
  customer: DemmiFS.User;
  onUpdated: () => void;
}

const parseNumber = (customer: DemmiFS.User) =>
  typeof customer.contactNumber === 'string'
    ? parsePhoneNumber(
        customer.contactNumber,
        customer.address.countryCode as Country,
      )?.formatInternational()
    : customer.contactNumber;

const CustomerCardBasicInfo: React.FC<Props> = ({ CSSBlock, customer, onUpdated }) => {
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const [title, setTitle] = useState<string | undefined>(customer.title);
  const [firstName, setFirstName] = useState<string | undefined>(customer.firstName);
  const [lastName, setLastName] = useState<string | undefined>(customer.lastName);
  const [nickname, setNickname] = useState<string | undefined>(customer.nickname);
  const [email, setEmail] = useState<string | undefined>(customer.email);
  const [contactNumber, setContactNumber] = useState<string | undefined>(parseNumber(customer));
  const [gender, setGender] = useState<number>(customer.gender);

  useEffect(() => {
    setIsValid(
      !!isBasicInfoValid({
        countryCode: customer.address.countryCode,
        title,
        firstName,
        lastName,
        nickname,
        email,
        contactNumber,
        gender,
      }),
    );
  }, [title, firstName, lastName, nickname, email, contactNumber, gender]);

  const onSave = async () => {
    if (isValid) {
      if (!customer || !contactNumber) return;

      setIsLoading(true);
      await updateCustomerBasicInfo(
        customer.docID,
        title,
        firstName,
        lastName,
        nickname,
        email,
        contactNumber,
        gender,
      ).then(() => onUpdated());

      setIsLoading(false);
      setEditMode(false);
    }
  };

  const onCancel = () => {
    setTitle(customer.title);
    setFirstName(customer.firstName);
    setLastName(customer.lastName);
    setNickname(customer.nickname);
    setEmail(customer.email);
    setContactNumber(parseNumber(customer));
    setGender(customer.gender);
    setEditMode(false);
  };

  const editModeActive = () => {
    return (
      <div className={`${CSSBlock}__content`}>
        <div className={`${CSSBlock}__item`}>
          <div className={`${CSSBlock}__item`}>
            <div className={`${CSSBlock}__item-key`}>Title</div>
            <Menu<string>
              content={getMenuContent()}
              triggerEl={<MenuTriggerButton text={title ?? '-'} styles={{ width: '100%' }} />}
              onClick={t => setTitle(t)}
            />
          </div>
          <div className={`${CSSBlock}__item`}>
            <div className={`${CSSBlock}__item-key`}>First Name</div>
            <DemmiInput
              type="text"
              value={firstName}
              placeholder={'First Name'}
              onChangeValue={v => setFirstName(v)}
            />
          </div>
          <div className={`${CSSBlock}__item`}>
            <div className={`${CSSBlock}__item-key`}>Last Name</div>
            <DemmiInput
              type="text"
              value={lastName}
              placeholder={'Last Name'}
              onChangeValue={v => setLastName(v)}
            />
          </div>
          <div className={`${CSSBlock}__item`}>
            <div className={`${CSSBlock}__item-key`}>Nickname</div>
            <DemmiInput
              type="text"
              value={nickname}
              placeholder={'Nickname'}
              onChangeValue={v => setNickname(v)}
            />
          </div>
          <div className={`${CSSBlock}__item`}>
            <div className={`${CSSBlock}__item-key`}>Email</div>
            <DemmiInput
              type="email"
              value={email}
              placeholder={'Email'}
              onChangeValue={v => setEmail(v)}
            />
          </div>
          <div className={`${CSSBlock}__item-key`}>Contact Number</div>
          <div className={`${CSSBlock}__item-value`}>
            <PhoneInput
              inputComponent={DemmiInputPhone}
              country={customer.address.countryCode as Country}
              value={contactNumber}
              onChange={e => setContactNumber(e?.toString() ?? '')}
            />
          </div>
          <div className={`${CSSBlock}__item`}>
            <div className={`${CSSBlock}__item-key`}>Gender</div>
            <DemmiButtonSwitch
              buttons={Object.values(DemmiHelpers.Customer.customerGenders)}
              initialIndex={gender}
              onClick={i => setGender(i)}
            />
          </div>
        </div>
      </div>
    );
  };
  const editModeInactive = () => {
    return (
      <div className={`${CSSBlock}__content`}>
        <div className={`${CSSBlock}__item`}>
          <div className={`${CSSBlock}__item-key`}>Title</div>
          <div className={`${CSSBlock}__item-value`}>{title}</div>
        </div>
        <div className={`${CSSBlock}__item`}>
          <div className={`${CSSBlock}__item-key`}>First Name</div>
          <div className={`${CSSBlock}__item-value`}>{firstName}</div>
        </div>
        <div className={`${CSSBlock}__item`}>
          <div className={`${CSSBlock}__item-key`}>Last Name</div>
          <div className={`${CSSBlock}__item-value`}>{lastName}</div>
        </div>
        <div className={`${CSSBlock}__item`}>
          <div className={`${CSSBlock}__item-key`}>Nickname</div>
          <div className={`${CSSBlock}__item-value`}>{nickname}</div>
        </div>
        <div className={`${CSSBlock}__item`}>
          <div className={`${CSSBlock}__item-key`}>Email</div>
          <div className={`${CSSBlock}__item-value`}>{email}</div>
        </div>
        <div className={`${CSSBlock}__item`}>
          <div className={`${CSSBlock}__item-key`}>Contact Number</div>
          <div className={`${CSSBlock}__item-value`}>{contactNumber}</div>
        </div>
        <div className={`${CSSBlock}__item`}>
          <div className={`${CSSBlock}__item-key`}>Gender</div>
          <div className={`${CSSBlock}__item-value`}>{DemmiHelpers.Customer.getGender(gender)}</div>
        </div>
      </div>
    );
  };
  const cardContent = () => (editMode ? editModeActive() : editModeInactive());

  return (
    <DemmiCard
      title="Basic Information"
      isLoading={isLoading}
      editMode={editMode}
      onClickEdit={() => setEditMode(true)}
      onCancel={onCancel}
      onSave={onSave}
      isValid={isValid}>
      {cardContent()}
    </DemmiCard>
  );
};

export default CustomerCardBasicInfo;
