import { doc, getDoc, updateDoc } from 'firebase/firestore';

import { FSDemmiAdminVendor, FSDemmiConstants } from '@models/demmi.model';
import { FSCollections } from '@providers/firestoreProvider';

import { getDemmiDB } from '../../../firebase';
import { doesVendorExist } from './_vendors';
import {
  FSCollectionNames,
  FSConstantDocNames,
  FSDocNames,
  FSSubCollectionNames,
} from './networkService';

export const getFSDemmiAdminVendor = async (
  vendorID: string,
): Promise<FSDemmiAdminVendor | undefined> => {
  console.log('getFSDemmiAdminVendor()', vendorID);
  const docRef = doc(
    FSCollections.DemmiAdminVendors([
      FSConstantDocNames.DEMMI_ADMIN,
      FSSubCollectionNames.DEMMI_ADMIN_VENDORS,
    ]),
    vendorID,
  );

  return getDoc(docRef).then(doc => {
    return doc.exists() ? (doc.data() as FSDemmiAdminVendor) : undefined;
  });
};

export const updateFSDemmiAdminVendor = async (
  vendorID: string,
  adminDetails: FSDemmiAdminVendor,
): Promise<void> => {
  console.log('updateFSDemmiAdminVendor()', vendorID);
  const exists = await doesVendorExist(vendorID);
  if (!exists) return;

  const docRef = doc(
    FSCollections.DemmiAdminVendors([
      FSConstantDocNames.DEMMI_ADMIN,
      FSSubCollectionNames.DEMMI_ADMIN_VENDORS,
    ]),
    vendorID,
  );

  return updateDoc(docRef, {
    ...adminDetails,
  });
};

export const getFSDemmiConstants = async (): Promise<FSDemmiConstants | undefined> => {
  return getDoc(doc(getDemmiDB(), FSCollectionNames.DEMMI, FSDocNames.DEMMI_CONSTANTS)).then(
    doc => {
      if (doc.exists()) {
        console.log(doc.data());
        let constants = doc.data() as FSDemmiConstants;
        constants.categories = constants.categories.map(parent => ({
          ...parent,
          children: parent.children.map(child => ({
            ...child,
          })),
        }));
        return constants;
      }
      return undefined;
    },
  );
};
