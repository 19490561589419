import React, { useRef, useState } from 'react';

import useComponentVisible from '@core/hooks/useComponentVisible';
import MenuCore from '@demmi-ui/Menu/MenuCore';
import { DemmiHelpers } from '@subhanhabib/demmilib';

interface Props {
  disabled?: boolean;
  times: number[];
  onClick: (val: number) => void;
  triggerEl: JSX.Element;
}

const InputTimeDropdown: React.FC<Props> = ({ times, disabled, onClick, triggerEl }) => {
  const CSSBlock = 'demmi-ui-input-time';
  const triggerRef = useRef<HTMLButtonElement>(null);
  const menuCoreRef = useRef<any>(null);
  const [triggerBounds, setTriggerBounds] = useState<DOMRect>();

  const gracefulClose = (onClose: () => void) => {
    menuCoreRef.current ? menuCoreRef.current.gracefulClose(onClose) : onClose();
  };

  const { ref, isVisible, setIsVisible } = useComponentVisible(
    false,
    () => new Promise<any>(resolve => gracefulClose(() => resolve(0))),
  );

  triggerEl = React.cloneElement(triggerEl, {
    onClick: () => {
      isVisible ? gracefulClose(() => setIsVisible(false)) : !disabled ? setIsVisible(true) : null;
      if (triggerRef.current) setTriggerBounds(triggerRef.current.getBoundingClientRect());
    },
    ref: triggerRef,
  });
  const onItemClick = (num: number) => {
    onClick(num);
    gracefulClose(() => setIsVisible(false));
  };

  return (
    <>
      {triggerEl}

      {triggerBounds && (
        <MenuCore
          ref={menuCoreRef}
          onExited={() => setIsVisible(false)}
          isOpen={isVisible}
          triggerBounds={triggerBounds}>
          <div className={`${CSSBlock}__overlay`} ref={ref}>
            <div className={`${CSSBlock}__scroll`}>
              {times.map((time, i) => (
                <div
                  className={`${CSSBlock}__scroll-item`}
                  key={i}
                  onClick={() => onItemClick(time)}>
                  {DemmiHelpers.DemmiTime.formateTimeSegment(time)}
                </div>
              ))}
            </div>
          </div>
        </MenuCore>
      )}
    </>
  );
};

export default InputTimeDropdown;
