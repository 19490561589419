import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import DemmiButtonSwitch from '@demmi-ui/ButtonSwitch';
import {
  RouteParams,
  RoutingViews,
  getRouteFromPath,
  getViewFromPath,
} from '@core/services/routingService/routingService';
import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {
  order: DemmiFS.Order;
}

const OrderView: React.FC<Props> = ({ order }) => {
  const CSSBlock = 'orders-view';
  const navigate = useNavigate();
  const location = useLocation();

  const tabs = [
    { label: 'Details', route: RoutingViews.ORDER_DETAILS },
    { label: 'Raw', route: RoutingViews.ORDER_RAW },
  ];
  const [activeTab, setActiveTab] = useState<RoutingViews | undefined>(RoutingViews.ORDER_DETAILS);

  useEffect(() => {
    const view = getViewFromPath(location.pathname);
    setActiveTab(view);
  }, [location]);

  const onClickTab = (view: RoutingViews) => {
    setActiveTab(view);
    navigate(getRouteFromPath(view, { [RouteParams.ORDER_ID]: order.docID }), {
      replace: true,
    });
  };

  const hasOrder = (order: DemmiFS.Order) => (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__title`}>{order.orderID}</div>
      <div className={`${CSSBlock}__subtitle`}>{order.collectionCode}</div>
      <div className={`${CSSBlock}__tabs`}>
        <DemmiButtonSwitch
          buttons={tabs.map(t => t.label)}
          index={tabs.findIndex(t => t.route === activeTab)}
          onClick={i => onClickTab(tabs[i].route)}
        />
      </div>
      <Outlet />
    </div>
  );
  const orderNotFound = () => <div className={`${CSSBlock}`}>Order Not Found</div>;

  return order ? hasOrder(order) : orderNotFound();
};

export default OrderView;
