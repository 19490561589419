import React, { useEffect, useRef, useState } from 'react';

interface SelfManaged extends Base {
  index: number;
}

interface Managed extends Base {
  initialIndex?: number;
}

interface Base {
  items: JSX.Element[];
  onClick: (i: number) => Promise<boolean> | void;
}

type Props = Managed | SelfManaged;

const isSelfManaged = (props: Props): props is SelfManaged => {
  return 'index' in props;
};

const DemmiItemSwitch: React.FC<Props> = props => {
  const CSSBlock = 'demmi-ui-item-switch';
  const { items, onClick } = props;

  const indicator = useRef<HTMLSpanElement>(null);
  const switchContainer = useRef<HTMLDivElement>(null);
  const initialButton = useRef<HTMLButtonElement>(null);

  const [active, setActive] = isSelfManaged(props)
    ? [props.index, () => {}]
    : useState<number | undefined>(props.initialIndex ?? -1);

  const updateIndicatorPos = (x: number, width: number) => {
    if (indicator.current) {
      indicator.current.style.setProperty('left', `${x}px`);
      indicator.current.style.setProperty('width', `${width}px`);
    }
  };

  const onItemClick = (i: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve(onClick(i)).then(res => {
      if (res !== false) {
        setActive(i);
        if (switchContainer.current) {
          const switchBounds = switchContainer.current?.getBoundingClientRect();
          const { x, width } = (e.target as HTMLButtonElement).getBoundingClientRect();
          updateIndicatorPos(x - switchBounds.x, width);
        }
      }
    });
  };

  useEffect(() => {
    isSelfManaged(props) ? setActive(props.index) : setActive(props.initialIndex);
    if (indicator.current && initialButton.current && switchContainer.current) {
      const switchBounds = switchContainer.current?.getBoundingClientRect();
      const { x, width } = initialButton.current.getBoundingClientRect();
      updateIndicatorPos(x - switchBounds.x, width);
    }
  }, [props]);

  return (
    <div className={`${CSSBlock}`} ref={switchContainer}>
      {items.map((item, i) => (
        <button
          className={`${CSSBlock}__item ${active === i ? `${CSSBlock}__item--active` : ''}`}
          key={i}
          {...(active === i ? { ref: initialButton } : {})}
          onClick={onItemClick(i)}>
          <span className={`${CSSBlock}__item-content`}>{item}</span>
        </button>
      ))}
      <span className={`${CSSBlock}__indicator`} ref={indicator}></span>
    </div>
  );
};

export default DemmiItemSwitch;
