import React from 'react';

import VendorShopDay from './_vendorShopDay';
import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {
  CSSBlock: string;
  editMode: boolean;
  hours: DemmiFS.VendorOpeningHours;
  setHours: React.Dispatch<React.SetStateAction<DemmiFS.VendorOpeningHours>>;
}

const VendorShopHours: React.FC<Props> = ({ CSSBlock, editMode, hours, setHours }) => {
  return (
    <div className={`${CSSBlock}__days`}>
      <VendorShopDay
        CSSBlock={CSSBlock}
        editMode={editMode}
        title="Monday"
        day="mon"
        hours={hours}
        setHours={setHours}
      />
      <VendorShopDay
        CSSBlock={CSSBlock}
        editMode={editMode}
        title="Tuesday"
        day="tue"
        hours={hours}
        setHours={setHours}
      />
      <VendorShopDay
        CSSBlock={CSSBlock}
        editMode={editMode}
        title="Wednesday"
        day="wed"
        hours={hours}
        setHours={setHours}
      />
      <VendorShopDay
        CSSBlock={CSSBlock}
        editMode={editMode}
        title="Thursday"
        day="thu"
        hours={hours}
        setHours={setHours}
      />
      <VendorShopDay
        CSSBlock={CSSBlock}
        editMode={editMode}
        title="Friday"
        day="fri"
        hours={hours}
        setHours={setHours}
      />
      <VendorShopDay
        CSSBlock={CSSBlock}
        editMode={editMode}
        title="Saturday"
        day="sat"
        hours={hours}
        setHours={setHours}
      />
      <VendorShopDay
        CSSBlock={CSSBlock}
        editMode={editMode}
        title="Sunday"
        day="sun"
        hours={hours}
        setHours={setHours}
      />
    </div>
  );
};

export default VendorShopHours;
