import React, { useState } from 'react';

import { useAuth } from '@providers/authProvider';

interface LoadingIntroProps {}

const LoadingAuth: React.FC<LoadingIntroProps> = ({}) => {
  const CSSBlock = 'loading-intro';
  const [hide, setHide] = useState(false);

  const onLoad = () => {
    const { user } = useAuth();
    if (!user) return;
  };

  onLoad();

  return (
    <div className={`${CSSBlock} ${CSSBlock}--auth ${hide ? `${CSSBlock}--hide` : ''}`}>
      <div className={`${CSSBlock}__background`}></div>
      <div className={`${CSSBlock}__title`}>
        <strong>P</strong>roject <strong>D</strong>eme
      </div>
      <div className={`${CSSBlock}__subtitle`}>Checking User Data</div>
      <div className={`${CSSBlock}__loading-indicator`}></div>
    </div>
  );
};

export default LoadingAuth;
