import React, { useEffect, useState } from 'react';

import { APIProvider, ControlPosition, Map, Marker } from '@vis.gl/react-google-maps';

import { CustomMapControl } from './partials/MapControl';
import MapHandler from './partials/MapHandler';
import { DemmiFS, DemmiHelpers } from '@subhanhabib/demmilib';

interface Props {
  countryCode: string;
  setAddress: React.Dispatch<React.SetStateAction<DemmiFS.DemmiAddress | undefined>>;
}

const GeoLocationMap: React.FC<Props> = ({ countryCode, setAddress }) => {
  const [selectedPlace, setSelectedPlace] = useState<google.maps.places.PlaceResult | null>(null);

  useEffect(() => {
    applyPlace();
  }, [selectedPlace]);

  const applyPlace = () => {
    if (!selectedPlace) return;
    const updatedVendorAddress = DemmiHelpers.Maps.mapPlaceToAddress(countryCode, selectedPlace);
    setAddress(updatedVendorAddress);
  };

  return (
    <APIProvider apiKey={process.env.DEMMI_GC_MAPS_API_KEY!}>
      <Map
        id="gmaps-view"
        style={{ height: '40rem' }}
        defaultCenter={{
          lat: DemmiHelpers.Maps.DefaultLatLng[0],
          lng: DemmiHelpers.Maps.DefaultLatLng[1],
        }}
        defaultZoom={14}
        gestureHandling={'greedy'}
        disableDefaultUI={true}>
        <CustomMapControl
          controlPosition={ControlPosition.TOP_LEFT}
          onPlaceSelect={setSelectedPlace}
        />
        {selectedPlace && selectedPlace.geometry?.location && (
          <Marker
            position={{
              lat: selectedPlace.geometry.location.lat(),
              lng: selectedPlace.geometry.location.lng(),
            }}
          />
        )}
      </Map>
      <MapHandler place={selectedPlace} />
    </APIProvider>
  );
};

export default GeoLocationMap;
