import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';

import Page from '@components/page/Page';
import { getOrders } from '@core/services/networkService/_orders';
import {
  RouteParams,
  RoutingViews,
  getRouteFromPath,
} from '@core/services/routingService/routingService';
import { demmiRequest } from '@helpers/app.helper';

import OrderView from './OrdersView';
import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {}

const Orders: React.FC<Props> = ({}) => {
  const CSSBlock = 'orders';
  const navigate = useNavigate();
  const { [RouteParams.ORDER_ID]: orderID } = useParams();

  const [orders, setOrders] = useState<DemmiFS.Order[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<DemmiFS.Order[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<DemmiFS.Order | undefined>();

  useEffect(() => {
    const order = orders.find(f => f.docID === orderID);
    if (order) {
      setSelectedOrder(undefined);
      setTimeout(() => {
        if (order) setSelectedOrder(order);
      }, 50);
    }
  }, [orderID, orders]);

  useEffect(() => onClickRefresh(), []);
  useEffect(() => setFilteredOrders(orders), [orders]);

  const onClickOrder = (index: number) => {
    const order = orders[index];
    setSelectedOrder(undefined);
    navigate(
      getRouteFromPath(RoutingViews.ORDER_DETAILS, {
        [RouteParams.ORDER_ID]: order.docID,
      }),
      {
        replace: true,
      },
    );
    setTimeout(() => {
      if (order) setSelectedOrder(order);
    }, 50);
  };

  const filterOrders = (filter: string) => {
    const filtered = orders.reduce((acc: DemmiFS.Order[], cur: DemmiFS.Order) => {
      if (filter && !cur.customer?.firstName.toLowerCase().includes(filter.toLowerCase()))
        return acc;
      return [...acc, cur];
    }, []);
    setFilteredOrders(filtered);
  };

  const onClickRefresh = () => {
    demmiRequest(getOrders()).then(f => {
      setOrders(f);
      console.log('onClickRefresh', f);
    });
    setSelectedOrder(undefined);
  };

  const sidebarButtons = () => {
    return filteredOrders.map(order => (
      <div className={`${CSSBlock}__sidebar-button`}>
        <span className={`${CSSBlock}__sidebar-button-orderID`}>{order.orderID}</span>
        {order.customer ? (
          <span
            className={`${CSSBlock}__sidebar-button-customer`}>{`${order.customer.title} ${order.customer.firstName} ${order.customer.lastName}`}</span>
        ) : (
          <span className={`${CSSBlock}__sidebar-button-customer`}>-</span>
        )}
        <span className={`${CSSBlock}__sidebar-button-date`}>
          {format(DemmiFS.getDateFromFirestore(order.orderDate) ?? '', 'dd MMM yyyy, HH:mm:ss')}
        </span>
      </div>
    ));
  };

  const sidebarTitleButtons = [{ faIcon: 'fa-rotate-right', onClick: onClickRefresh }];

  return (
    <Page
      sidebarFixed={!selectedOrder}
      sidebarButtons={sidebarButtons()}
      sidebarActive={orders.findIndex(f => selectedOrder?.docID === f.docID)}
      sidebarTitle="Order"
      sidebarTitleButtons={sidebarTitleButtons}
      sidebarOnClick={onClickOrder}
      sidebarOnSearch={filterOrders}
      sidebarSearchPlaceholder="Filter Orders">
      {selectedOrder && <OrderView order={selectedOrder} />}
    </Page>
  );
};

export default Orders;
