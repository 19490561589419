import { QueryDocumentSnapshot } from 'firebase/firestore';

import { DemmiFS } from '@subhanhabib/demmilib';

import {
  NotificationCustomerWithVendor,
  NotificationVendorWithCustomer,
} from '@models/notifications.model';

export const parseToNotificationVendor = (
  doc: QueryDocumentSnapshot<DemmiFS.FSNotificationVendor>,
  customer?: DemmiFS.User,
): NotificationVendorWithCustomer => {
  const type = DemmiFS.NotificationHelper.Admin.getNotificationType(doc.data().type);

  return {
    source: 'vendor',
    ...(doc.data() as DemmiFS.FSNotificationVendor),
    // timestamp,
    type,
    customer,
    docID: doc.id,
    raw: doc.data(),
  };
};
export const parseToNotificationCustomer = (
  doc: QueryDocumentSnapshot<DemmiFS.FSNotificationCustomer>,
  vendor?: DemmiFS.Vendor,
): NotificationCustomerWithVendor => {
  const type = DemmiFS.NotificationHelper.Admin.getNotificationType(doc.data().type);

  return {
    source: 'customer',
    ...(doc.data() as DemmiFS.NotificationCustomer),
    // timestamp,
    type,
    vendor,
    docID: doc.id,
    raw: doc.data(),
  };
};
