import { Unsubscribe } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';

import { listenToNotificationsVendor } from '@core/services/networkService/notifications/notificationsVendor';
import DemmiIconButton from '@demmi-ui/IconButton';

import { Logger } from '@subhanhabib/demmilib';

import NotificationsPanel from './NotificationsPanel';
import { listenToNotificationsCustomer } from '@core/services/networkService/notifications/notificationsCustomer';
import {
  NotificationCustomerWithVendor,
  NotificationVendorWithCustomer,
} from '@models/notifications.model';

const Notifications: React.FC = () => {
  const CSSBlock = 'notifications';

  const [isOpen, setIsOpen] = useState(false);
  const [notificationsVendor, setNotificationsVendor] = useState<NotificationVendorWithCustomer[]>(
    [],
  );
  const [notificationsCustomer, setNotificationsCustomer] = useState<
    NotificationCustomerWithVendor[]
  >([]);

  useEffect(() => {
    let snapshotListenerVendor: Unsubscribe | undefined;
    const cbVendor = (notifications: NotificationVendorWithCustomer[]) => {
      setNotificationsVendor(notifications);
      Logger({ objs: { notifications } }, Notifications);
    };
    listenToNotificationsVendor(cbVendor).then(u => (snapshotListenerVendor = u));

    let snapshotListenerCustomer: Unsubscribe | undefined;
    const cbCustomer = (notifications: NotificationCustomerWithVendor[]) => {
      setNotificationsCustomer(notifications);
      Logger({ objs: { notifications } }, Notifications);
    };
    listenToNotificationsCustomer(cbCustomer).then(u => (snapshotListenerCustomer = u));

    return () => {
      if (snapshotListenerVendor) snapshotListenerVendor();
      if (snapshotListenerCustomer) snapshotListenerCustomer();
    };
  }, []);

  return (
    <>
      <span className={`${CSSBlock}__icon`}>
        <DemmiIconButton faIcon="fa-regular fa-bell" onClick={() => setIsOpen(true)} />
        {[...notificationsVendor, ...notificationsCustomer].filter(n => !n.read).length > 0 && (
          <span className={`${CSSBlock}__icon-indicator`}>
            {[...notificationsVendor, ...notificationsCustomer].filter(n => !n.read).length}
          </span>
        )}
      </span>
      <NotificationsPanel
        isOpen={isOpen}
        notificationsVendor={notificationsVendor}
        notificationsCustomer={notificationsCustomer}
        onExited={() => setIsOpen(false)}
      />
    </>
  );
};

export default Notifications;
