import { doc, query } from 'firebase/firestore';

import { FSCollections } from '@providers/firestoreProvider';
import { FSSubCollectionNames } from '../networkService';

export const demmiSupportQuery = () => query(FSCollections.DemmiSupport);

export const demmiSupportChatQuery = (chatDocID: string) =>
  doc(FSCollections.DemmiSupport, chatDocID);

export const demmiSupportChatMessagesQuery = (chatDocID: string) =>
  FSCollections.DemmiSupportMessages([chatDocID, FSSubCollectionNames.DEMMI_SUPPORT_MESSAGES]);
