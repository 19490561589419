import {
  DocumentData,
  QueryDocumentSnapshot,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from 'firebase/firestore';

import { FSCollections } from '@providers/firestoreProvider';

import { getDemmiDB } from '../../../firebase';
import { FSCollectionNames } from './networkService';
import { DemmiFS } from '@subhanhabib/demmilib';

export const doesCustomerExist = async (customerDocID: string): Promise<boolean> => {
  const docSnap = await getDoc(doc(getDemmiDB(), FSCollectionNames.USERS, customerDocID));
  return docSnap.exists();
};

export const getCustomers = async (): Promise<DemmiFS.UserWithRaw[]> => {
  const querySnapshot = await getDocs(FSCollections.Users);
  const customers: DemmiFS.UserWithRaw[] = [];
  querySnapshot.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
    customers.push({
      docID: doc.id,
      raw: doc.data(),
      ...(doc.data() as DemmiFS.FSUser),
    });
  });
  return customers;
};

export const getCustomer = async (customerID: string): Promise<DemmiFS.UserWithRaw | undefined> => {
  const docSnap = await getDoc(doc(getDemmiDB(), FSCollectionNames.USERS, customerID));

  if (docSnap.exists()) {
    return {
      ...(docSnap.data() as DemmiFS.User),
      docID: docSnap.id,
      raw: docSnap.data(),
    };
  } else console.log('No such document!');
  return undefined;
};

export const updateCustomerAddress = async (
  customerDocID: string,
  address: DemmiFS.DemmiAddress,
): Promise<any> => {
  const docRef = doc(FSCollections.Users, customerDocID);
  return updateDoc(docRef, {
    address: {
      geo: address.geo,
      countryCode: address.countryCode,
      address: address.address,
    },
  });
};

export const updateCustomerBasicInfo = async (
  customerDocID: string,
  title?: string,
  firstName?: string,
  lastName?: string,
  nickname?: string,
  email?: string,
  contactNumber?: string,
  gender?: number,
): Promise<void> => {
  const exists = await doesCustomerExist(customerDocID);
  if (!exists) return;

  const docRef = doc(FSCollections.Users, customerDocID);
  return updateDoc(docRef, {
    ...(title ? { title } : {}),
    ...(firstName ? { firstName } : {}),
    ...(lastName ? { lastName } : {}),
    ...(nickname ? { nickname } : {}),
    ...(email ? { email } : {}),
    ...(contactNumber ? { contactNumber } : {}),
    ...(gender ? { gender } : {}),
  });
};
