import React, { useEffect, useState } from 'react';

import { getVendorImage, getProductImage } from '@core/services/networkService/_storage';
import { demmiRequest } from '@helpers/app.helper';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  src: string;
  vendorID: string;
  isVendor?: boolean;
}

const AsyncImage: React.FC<Props> = ({ src, vendorID, isVendor, ...props }) => {
  const CSSBlock = 'async-image';
  const [loadedSrc, setLoadedSrc] = useState<string>();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    setLoadedSrc(undefined);
    if (src) {
      demmiRequest(isVendor ? getVendorImage(vendorID, src) : getProductImage(vendorID, src)).then(
        url => {
          const handleLoad = () => setLoadedSrc(url);
          const image = new Image();
          image.addEventListener('load', handleLoad);
          image.src = url;
          image.complete;
          return () => {
            image.removeEventListener('load', handleLoad);
          };
        },
      );
    }
  }, [src]);

  const onImageLoaded = () => {
    setHasLoaded(true);
    setTimeout(() => {
      setCompleted(true);
    }, 200);
  };

  return (
    <span className={`${CSSBlock} ${hasLoaded ? `${CSSBlock}--loaded` : ``}`}>
      {loadedSrc && <img src={loadedSrc} {...props} onLoad={onImageLoaded} />}

      {!completed && (
        <div className={`${CSSBlock}__loading-indicator-wrapper`}>
          <div className={`${CSSBlock}__loading-indicator`} />
        </div>
      )}
    </span>
  );
};

export default AsyncImage;
