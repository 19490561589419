import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import {
  RouteParams,
  RoutingViews,
  getRouteFromPath,
  getViewFromPath,
} from '@core/services/routingService/routingService';
import DemmiButtonSwitch from '@demmi-ui/ButtonSwitch';
import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {
  vendor: DemmiFS.Vendor;
}

const VendorView: React.FC<Props> = ({ vendor }) => {
  const CSSBlock = 'vendors-view';
  const navigate = useNavigate();
  const location = useLocation();

  const tabs = [
    { label: 'Details', route: RoutingViews.VENDORS_DETAILS },
    { label: 'Address', route: RoutingViews.VENDORS_ADDRESS },
    { label: 'Stripe', route: RoutingViews.VENDORS_STRIPE },
    { label: 'Inventory', route: RoutingViews.VENDORS_INVENTORY },
    { label: 'Analytics', route: RoutingViews.VENDORS_ANALYTICS },
    { label: 'Raw', route: RoutingViews.VENDORS_RAW },
  ];
  const [activeTab, setActiveTab] = useState<RoutingViews | undefined>(
    RoutingViews.VENDORS_DETAILS,
  );

  useEffect(() => {
    const view = getViewFromPath(location.pathname);
    setActiveTab(view);
  }, [location]);

  const onClickTab = (view: RoutingViews) => {
    setActiveTab(view);
    navigate(getRouteFromPath(view, { [RouteParams.VENDOR_ID]: vendor.docID }), {
      replace: true,
    });
  };

  const hasVendor = (vendor: DemmiFS.Vendor) => (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__title`}>{vendor.name}</div>
      <div className={`${CSSBlock}__tabs`}>
        <DemmiButtonSwitch
          buttons={tabs.map(t => t.label)}
          index={tabs.findIndex(t => t.route === activeTab)}
          onClick={i => onClickTab(tabs[i].route)}
        />
      </div>
      <Outlet />
    </div>
  );
  const vendorNotFound = () => <div className={`${CSSBlock}`}></div>;

  return vendor ? hasVendor(vendor) : vendorNotFound();
};

export default VendorView;
