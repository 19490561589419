import { DemmiFS } from '@subhanhabib/demmilib';

export interface NotificationCustomerWithVendor extends DemmiFS.NotificationCustomerWithRaw {
  source: 'customer' | 'vendor';
  vendor?: DemmiFS.Vendor;
}

export interface NotificationVendorWithCustomer extends DemmiFS.NotificationVendorWithRaw {
  source: 'customer' | 'vendor';
  customer?: DemmiFS.User;
}

export const isNotificationCustomer = (
  props: NotificationCustomerWithVendor | NotificationVendorWithCustomer,
): props is NotificationCustomerWithVendor => {
  return 'source' in props && props.source === 'customer';
};

export const isNotificationVendor = (
  props: NotificationCustomerWithVendor | NotificationVendorWithCustomer,
): props is NotificationVendorWithCustomer => {
  return 'source' in props && props.source === 'vendor';
};
