import { matchPath } from 'react-router-dom';

export enum SearchParams {
  VENDOR_ID = 'vendorID',
}

export enum RouteParams {
  SUPPORT_CHAT_ID = 'chatID',
  VENDOR_ID = 'vendorID',
  PRODUCT_ID = 'productID',
  ORDER_ID = 'orderID',
  CUSTOMER_ID = 'customerID',
  INVITE_CODE_ID = 'inviteCodeID',
}

export enum RoutingViews {
  HOME = 'home',

  SUPPORT = 'support',
  SUPPORT_CHAT = 'support-chat',

  VENDORS = 'vendors',
  VENDORS_DETAILS = 'vendors-details',
  VENDORS_ADDRESS = 'vendors-address',
  VENDORS_STRIPE = 'vendors-payments',
  VENDORS_INVENTORY = 'vendors-inventory',
  VENDORS_INVENTORY_RAW = 'vendors-inventory-raw',
  VENDORS_INVENTORY_DETAILS = 'vendors-inventory-details',
  VENDORS_INVENTORY_VARIANTS = 'vendors-inventory-variants',
  VENDORS_ANALYTICS = 'vendors-analytics',
  VENDORS_RAW = 'vendors-raw',

  CUSTOMERS = 'customers',
  CUSTOMERS_DETAILS = 'customers-details',
  CUSTOMERS_ADDRESS = 'customers-address',
  CUSTOMERS_RAW = 'customers-raw',

  ORDERS = 'orders',
  ORDER_DETAILS = 'order-details',
  ORDER_RAW = 'order-raw',

  REVIEWS = 'reviews',

  INVITE_CODES = 'invite-codes',
  INVITE_CODES_DETAILS = 'invite-codes-details',

  ANALYTICS = 'analytics',
  TRANSACTIONS = 'transactions',
  ADMIN = 'admin',
}

const _generatePath = (root: RoutingViews, ...children: (RoutingViews | RouteParams)[]) => {
  let path = `/${root}`;
  children.forEach(child => {
    if (Object.values(RouteParams).includes(child as RouteParams)) {
      path = `${path}/:${child}`;
    } else {
      path = `${path}/${child.replaceAll(`${root}-`, '')}`;
    }
  });
  return path;
};

export const routingPaths = {
  [RoutingViews.HOME]: `/${RoutingViews.HOME}`,

  [RoutingViews.SUPPORT]: _generatePath(RoutingViews.SUPPORT),
  [RoutingViews.SUPPORT_CHAT]: _generatePath(
    RoutingViews.SUPPORT,
    RouteParams.SUPPORT_CHAT_ID,
    RoutingViews.SUPPORT_CHAT,
  ),

  /**
   * Vendors
   */
  [RoutingViews.VENDORS]: _generatePath(RoutingViews.VENDORS),
  [RoutingViews.VENDORS_RAW]: _generatePath(
    RoutingViews.VENDORS,
    RouteParams.VENDOR_ID,
    RoutingViews.VENDORS_RAW,
  ),
  [RoutingViews.VENDORS_DETAILS]: _generatePath(
    RoutingViews.VENDORS,
    RouteParams.VENDOR_ID,
    RoutingViews.VENDORS_DETAILS,
  ),
  [RoutingViews.VENDORS_ADDRESS]: _generatePath(
    RoutingViews.VENDORS,
    RouteParams.VENDOR_ID,
    RoutingViews.VENDORS_ADDRESS,
  ),
  [RoutingViews.VENDORS_STRIPE]: _generatePath(
    RoutingViews.VENDORS,
    RouteParams.VENDOR_ID,
    RoutingViews.VENDORS_STRIPE,
  ),
  [RoutingViews.VENDORS_ANALYTICS]: _generatePath(
    RoutingViews.VENDORS,
    RouteParams.VENDOR_ID,
    RoutingViews.VENDORS_ANALYTICS,
  ),
  [RoutingViews.VENDORS_INVENTORY]: _generatePath(
    RoutingViews.VENDORS,
    RouteParams.VENDOR_ID,
    RoutingViews.VENDORS_INVENTORY,
  ),
  [RoutingViews.VENDORS_INVENTORY_RAW]: _generatePath(
    RoutingViews.VENDORS,
    RouteParams.VENDOR_ID,
    RoutingViews.VENDORS_INVENTORY,
    RouteParams.PRODUCT_ID,
    RoutingViews.VENDORS_INVENTORY_RAW,
  ),
  [RoutingViews.VENDORS_INVENTORY_DETAILS]: _generatePath(
    RoutingViews.VENDORS,
    RouteParams.VENDOR_ID,
    RoutingViews.VENDORS_INVENTORY,
    RouteParams.PRODUCT_ID,
    RoutingViews.VENDORS_INVENTORY_DETAILS,
  ),
  [RoutingViews.VENDORS_INVENTORY_VARIANTS]: _generatePath(
    RoutingViews.VENDORS,
    RouteParams.VENDOR_ID,
    RoutingViews.VENDORS_INVENTORY,
    RouteParams.PRODUCT_ID,
    RoutingViews.VENDORS_INVENTORY_VARIANTS,
  ),

  /**
   * Customers
   */
  [RoutingViews.CUSTOMERS]: _generatePath(RoutingViews.CUSTOMERS),
  [RoutingViews.CUSTOMERS_DETAILS]: _generatePath(
    RoutingViews.CUSTOMERS,
    RouteParams.CUSTOMER_ID,
    RoutingViews.CUSTOMERS_DETAILS,
  ),
  [RoutingViews.CUSTOMERS_ADDRESS]: _generatePath(
    RoutingViews.CUSTOMERS,
    RouteParams.CUSTOMER_ID,
    RoutingViews.CUSTOMERS_ADDRESS,
  ),
  [RoutingViews.CUSTOMERS_RAW]: _generatePath(
    RoutingViews.CUSTOMERS,
    RouteParams.CUSTOMER_ID,
    RoutingViews.CUSTOMERS_RAW,
  ),

  /**
   * Orders
   */
  [RoutingViews.ORDERS]: _generatePath(RoutingViews.ORDERS),
  [RoutingViews.ORDER_DETAILS]: _generatePath(
    RoutingViews.ORDERS,
    RouteParams.ORDER_ID,
    RoutingViews.ORDER_DETAILS,
  ),
  [RoutingViews.ORDER_RAW]: _generatePath(
    RoutingViews.ORDERS,
    RouteParams.ORDER_ID,
    RoutingViews.ORDER_RAW,
  ),

  /**
   * Reviews
   */
  [RoutingViews.REVIEWS]: _generatePath(RoutingViews.REVIEWS),

  /**
   * Invite codes
   */
  [RoutingViews.INVITE_CODES]: _generatePath(RoutingViews.INVITE_CODES),
  [RoutingViews.INVITE_CODES_DETAILS]: _generatePath(
    RoutingViews.INVITE_CODES,
    RouteParams.INVITE_CODE_ID,
    RoutingViews.INVITE_CODES_DETAILS,
  ),

  [RoutingViews.ANALYTICS]: _generatePath(RoutingViews.ANALYTICS),
  [RoutingViews.TRANSACTIONS]: _generatePath(RoutingViews.TRANSACTIONS),
  [RoutingViews.ADMIN]: `/${RoutingViews.ADMIN}`,
};

export const getRouteFromPath = (path: RoutingViews, replacements: { [key: string]: any }) => {
  let p = routingPaths[path];
  Object.entries(replacements).forEach(([key, val]) => {
    p = p.replace(`:${key}`, val);
  });
  console.log('getRouteFromPath', p);
  return p;
};

export const getViewFromPath = (path: string, exact = true) => {
  return Object.values(RoutingViews).find(view => {
    return matchPath(exact ? routingPaths[view] : routingPaths[view] + '/*', path);
  });
};
