import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import RawDetails from '@components/rawDetails/RawDetails';

import { RouteParams } from '@core/services/routingService/routingService';
import { demmiRequest } from '@helpers/app.helper';
import { getVendor } from '@core/services/networkService/_vendors';
import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {}

const VendorRaw: React.FC<Props> = ({}) => {
  const { [RouteParams.VENDOR_ID]: vendorID } = useParams();
  const [vendor, setVendor] = useState<DemmiFS.VendorWithRaw>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    refresh();
  }, [vendorID]);

  const refresh = () => {
    if (vendorID) {
      setIsLoading(true);
      demmiRequest(getVendor(vendorID))
        .then(f => {
          if (f) setVendor(f);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  };

  return vendor ? <RawDetails data={vendor.raw} /> : <></>;
};

export default VendorRaw;
