import React from 'react';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export enum IconButtonSize {
  SMALL = 'small',
  MINI = 'mini',
}

interface DemmiIconButtonProps {
  faIcon?: string;
  icon?: IconDefinition;
  className?: string;
  size?: IconButtonSize;
  onClick?: (e: React.MouseEvent) => void;
  tabIndex?: number;
  invert?: boolean;
  disabled?: boolean;
  styles?: React.CSSProperties;
}

const CSSBlock = 'demmi-ui-icon-button';
export const faStyles = ['fa-solid', 'fa-regular', 'fa-light', 'fa-thin', 'fa-duotone'];
const DemmiIconButton = React.forwardRef<HTMLButtonElement, DemmiIconButtonProps>(
  ({ faIcon, icon, className, onClick, size, tabIndex, disabled, invert, styles }, ref) => {
    return (
      <button
        className={`${CSSBlock} ${size ? `${CSSBlock}--${size}` : ''} ${
          invert ? `${CSSBlock}--invert` : ''
        } ${className || ''}`}
        onClick={onClick}
        ref={ref}
        disabled={disabled}
        style={styles}
        {...(tabIndex ? { tabIndex: tabIndex } : {})}>
        {icon ? (
          <FontAwesomeIcon icon={icon} />
        ) : (
          faIcon && (
            <i
              className={
                faStyles.find(item => faIcon.includes(item)) ? faIcon : `fa-solid ${faIcon}`
              }></i>
          )
        )}
      </button>
    );
  }
);

export default DemmiIconButton;
