import React, { PropsWithChildren } from 'react';

import Sidebar, { SidebarTitleButton } from '@components/sidebar/Sidebar';

interface Props extends PropsWithChildren {
  sidebarFixed?: boolean;
  sidebarActive: number;
  sidebarButtons: JSX.Element[];
  sidebarOnClick: (index: number) => void;
  sidebarTitle: string;
  sidebarTitleButtons?: (SidebarTitleButton | JSX.Element)[];
  sidebarOnSearch?: (val: string) => void;
  sidebarSearchPlaceholder?: string;
}

const Page: React.FC<Props> = ({
  sidebarFixed,
  sidebarActive,
  sidebarButtons,
  sidebarOnClick,
  sidebarOnSearch,
  sidebarTitle,
  sidebarTitleButtons,
  sidebarSearchPlaceholder,
  children,
}) => {
  const CSSBlock = 'page';

  return (
    <div className={`${CSSBlock} ${sidebarFixed ? `${CSSBlock}--sidebar-fixed` : ''}`}>
      <Sidebar
        fixed={sidebarFixed}
        customButtons={sidebarButtons}
        active={sidebarActive}
        title={sidebarTitle}
        titleButtons={sidebarTitleButtons}
        onClick={sidebarOnClick}
        onSearch={sidebarOnSearch}
        searchPlaceholder={sidebarSearchPlaceholder}
      />
      <div className={`${CSSBlock}__view`}>{children}</div>
    </div>
  );
};

export default Page;
