import React, { PropsWithChildren } from 'react';
import { To } from 'react-router-dom';

import LogoWhite from '@assets/demmi-logo-white.svg';
import Logo from '@assets/demmi-logo.svg';
import { useAppDispatch } from '@core/hooks/appHooks';

import { useAuth } from '@providers/authProvider';

import ThemeSwitcher from '../themeSwitcher/ThemeSwitcher';
import PortalSidebarButton, { PortalSidebarButtonProps } from './PortalSidebarButton';
import DemmiIconButton from '@demmi-ui/IconButton';
import {
  RoutingViews,
  getViewFromPath,
  routingPaths,
} from '@core/services/routingService/routingService';

interface Props extends PropsWithChildren {
  navigateTransitioned: (to: To) => void;
}

const PortalSidebar: React.FC<Props> = ({ navigateTransitioned }) => {
  const CSSBlock = 'portal-sidebar';
  const dispatch = useAppDispatch();
  const view = getViewFromPath(location.pathname, false);
  const { logOut } = useAuth();

  const buttons: PortalSidebarButtonProps[] = [
    {
      text: 'Home',
      faIcon: 'fa-light fa-home',
      active: view === RoutingViews.HOME,
      onClick: () => navigateTransitioned(routingPaths[RoutingViews.HOME]),
    },
    {
      text: 'Invite Codes',
      faIcon: 'fa-light fa-envelope',
      active: view === RoutingViews.INVITE_CODES,
      onClick: () => navigateTransitioned(routingPaths[RoutingViews.INVITE_CODES]),
    },
    {
      text: 'Vendors',
      // disabled: true,
      faIcon: 'fa-light fa-farm',
      active: view === RoutingViews.VENDORS,
      onClick: () => navigateTransitioned(routingPaths[RoutingViews.VENDORS]),
    },
    {
      text: 'Vendor Support',
      faIcon: 'fa-regular fa-message-bot',
      active: view === RoutingViews.SUPPORT,
      onClick: () => navigateTransitioned(routingPaths[RoutingViews.SUPPORT]),
    },
    {
      text: 'Customers',
      faIcon: 'fa-light fa-user-alien',
      active: view === RoutingViews.CUSTOMERS,
      onClick: () => navigateTransitioned(routingPaths[RoutingViews.CUSTOMERS]),
    },
    {
      text: 'Customer Support',
      disabled: true,
      faIcon: 'fa-regular fa-message-bot',
    },
    {
      text: 'Orders',
      faIcon: 'fa-light fa-basket-shopping',
      active: view === RoutingViews.ORDERS,
      onClick: () => navigateTransitioned(routingPaths[RoutingViews.ORDERS]),
    },
    {
      text: 'Reviews',
      disabled: true,
      faIcon: 'fa-light fa-comment',
      active: view === RoutingViews.REVIEWS,
      onClick: () => navigateTransitioned(routingPaths[RoutingViews.REVIEWS]),
    },
    {
      text: 'Settings',
      disabled: true,
      faIcon: 'fa-light fa-cog',
    },
  ];

  return (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__logo`}>
        <img src={Logo} className={`${CSSBlock}__logo-colour`} />
        <img src={LogoWhite} className={`${CSSBlock}__logo-white`} />
      </div>
      <div className={`${CSSBlock}__buttons`}>
        {buttons.map((button, i) => (
          <PortalSidebarButton key={i} {...button} />
        ))}
      </div>
      <div className={`${CSSBlock}__bottom`}>
        <div className={`${CSSBlock}__logout-button`}>
          <DemmiIconButton
            invert
            faIcon="fa-arrow-right-from-bracket"
            onClick={() => logOut(() => dispatch({ type: 'LOGOUT' }))}
          />
        </div>
        <ThemeSwitcher />
      </div>
    </div>
  );
};

export default PortalSidebar;
