import React from 'react';

import DemmiCard from '@demmi-ui/Card/Card';
import InputTime from '@demmi-ui/InputTime/InputTime';
import { DemmiFS } from '@subhanhabib/demmilib';

interface Props<K extends keyof DemmiFS.VendorOpeningHours> {
  CSSBlock: string;
  editMode: boolean;
  hours: DemmiFS.VendorOpeningHours;
  setHours: React.Dispatch<React.SetStateAction<DemmiFS.VendorOpeningHours>>;
  title: string;
  day: K;
}

const VendorShopDay = <K extends keyof DemmiFS.VendorOpeningHours>({
  CSSBlock,
  editMode,
  hours,
  setHours,
  title,
  day,
}: Props<K>) => {
  return (
    <DemmiCard className={`${CSSBlock}__details-item`}>
      <div className={`${CSSBlock}__details-title`}>{title}</div>
      <div className={`${CSSBlock}__details-hours`}>
        <div className={`${CSSBlock}__details-hours-item`}>
          <div className={`${CSSBlock}__details-hours-item-title`}>Open</div>

          <InputTime
            value={hours[day].open}
            disabled={!editMode}
            onChange={val => {
              console.log(val);
              setHours({
                ...hours,
                [day]: {
                  ...hours[day],
                  open: val,
                },
              });
            }}
          />
        </div>
        <div className={`${CSSBlock}__details-hours-item`}>
          <div className={`${CSSBlock}__details-hours-item-title`}>Close</div>
          <InputTime
            value={hours[day].close}
            disabled={!editMode}
            onChange={val => {
              console.log(val);
              setHours({
                ...hours,
                [day]: {
                  ...hours[day],
                  close: val,
                },
              });
            }}
          />
        </div>
      </div>
    </DemmiCard>
  );
  // );
};

export default VendorShopDay;
