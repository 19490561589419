import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import {
  RouteParams,
  RoutingViews,
  getRouteFromPath,
  getViewFromPath,
} from '@core/services/routingService/routingService';
import DemmiButtonSwitch from '@demmi-ui/ButtonSwitch';
import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {
  customer: DemmiFS.User;
}

const CustomerView: React.FC<Props> = ({ customer }) => {
  const CSSBlock = 'customers-view';
  const navigate = useNavigate();
  const location = useLocation();

  const tabs = [
    { label: 'Details', route: RoutingViews.CUSTOMERS_DETAILS },
    { label: 'Address', route: RoutingViews.CUSTOMERS_ADDRESS },
    { label: 'Raw', route: RoutingViews.CUSTOMERS_RAW },
  ];
  const [activeTab, setActiveTab] = useState<RoutingViews | undefined>(
    RoutingViews.CUSTOMERS_DETAILS,
  );

  useEffect(() => {
    const view = getViewFromPath(location.pathname);
    setActiveTab(view);
  }, [location]);

  const onClickTab = (view: RoutingViews) => {
    setActiveTab(view);
    navigate(getRouteFromPath(view, { [RouteParams.CUSTOMER_ID]: customer.docID }), {
      replace: true,
    });
  };

  const hasCustomer = (customer: DemmiFS.User) => (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__title`}>{`${customer.title} ${
        customer.firstName
      } ${customer.lastName.substring(0, 1)}.`}</div>
      <div className={`${CSSBlock}__subtitle`}>
        DocID: <span>{customer.docID}</span>
      </div>
      <div className={`${CSSBlock}__tabs`}>
        <DemmiButtonSwitch
          buttons={tabs.map(t => t.label)}
          index={tabs.findIndex(t => t.route === activeTab)}
          onClick={i => onClickTab(tabs[i].route)}
        />
      </div>
      <div className={`${CSSBlock}__content`}></div>
      <Outlet />
    </div>
  );
  const customerNotFound = () => <div className={`${CSSBlock}`}></div>;

  return customer ? hasCustomer(customer) : customerNotFound();
};

export default CustomerView;
