import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Page from '@components/page/Page';
import { getCustomers } from '@core/services/networkService/_customer';
import {
  RouteParams,
  RoutingViews,
  getRouteFromPath,
} from '@core/services/routingService/routingService';
import { demmiRequest } from '@helpers/app.helper';

import CustomerView from './CustomerView';
import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {}

const Customers: React.FC<Props> = ({}) => {
  const CSSBlock = 'customers';
  const navigate = useNavigate();
  const { [RouteParams.CUSTOMER_ID]: customerID } = useParams();

  const [customers, setCustomers] = useState<DemmiFS.User[]>([]);
  const [filteredCustomers, setFilteredCustomers] = useState<DemmiFS.User[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<DemmiFS.User | undefined>();

  useEffect(() => {
    const customer = customers.find(f => f.docID === customerID);
    if (customer) {
      setSelectedCustomer(undefined);
      setTimeout(() => {
        if (customer) setSelectedCustomer(customer);
      }, 50);
    }
  }, [customerID, customers]);

  useEffect(() => onClickRefresh(), []);
  useEffect(() => setFilteredCustomers(customers), [customers]);

  const onClickCustomer = (index: number) => {
    const customer = customers[index];
    setSelectedCustomer(undefined);
    navigate(
      getRouteFromPath(RoutingViews.CUSTOMERS_DETAILS, {
        [RouteParams.CUSTOMER_ID]: customer.docID,
      }),
      {
        replace: true,
      },
    );
    setTimeout(() => {
      if (customer) setSelectedCustomer(customer);
    }, 50);
  };

  const filterCustomers = (filter: string) => {
    const filtered = customers.reduce((acc: DemmiFS.User[], cur: DemmiFS.User) => {
      if (filter && !cur.firstName.toLowerCase().includes(filter.toLowerCase())) return acc;
      return [...acc, cur];
    }, []);
    setFilteredCustomers(filtered);
  };

  const onClickRefresh = () => {
    demmiRequest(getCustomers()).then(f => setCustomers(f));
    setSelectedCustomer(undefined);
  };

  const sidebarButtons = () => {
    return filteredCustomers.map(customer => (
      <div className={`${CSSBlock}__sidebar-button`}>
        <span
          className={`${CSSBlock}__sidebar-button-text`}>{`${customer.title} ${customer.firstName} ${customer.lastName}`}</span>
        <span className={`${CSSBlock}__sidebar-button-address`}>{customer.email}</span>
      </div>
    ));
  };

  return (
    <Page
      sidebarFixed={!selectedCustomer}
      sidebarButtons={sidebarButtons()}
      sidebarActive={customers.findIndex(f => selectedCustomer?.docID === f.docID)}
      sidebarTitle="Customer"
      sidebarOnClick={onClickCustomer}
      sidebarOnSearch={filterCustomers}
      sidebarSearchPlaceholder="Filter Customers">
      {selectedCustomer && <CustomerView customer={selectedCustomer} />}
    </Page>
  );
};

export default Customers;
