import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import RawDetails from '@components/rawDetails/RawDetails';

import { RouteParams } from '@core/services/routingService/routingService';
import { demmiRequest } from '@helpers/app.helper';
import { DemmiFS } from '@subhanhabib/demmilib';
import { getOrder } from '@core/services/networkService/_orders';

interface Props {}

const OrderRaw: React.FC<Props> = ({}) => {
  const { [RouteParams.ORDER_ID]: orderID } = useParams();

  const [order, setOrder] = useState<DemmiFS.OrderWithRaw>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    refreshOrder();
  }, [orderID]);

  const refreshOrder = () => {
    if (orderID) {
      setIsLoading(true);
      demmiRequest(getOrder(orderID))
        .then(f => {
          setOrder(f);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  };

  return order ? <RawDetails data={order.raw} /> : <></>;
};

export default OrderRaw;
