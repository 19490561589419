import { store } from '@core/store/store';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Firebase configuration
const firebaseConfigDev = {
  apiKey: process.env.DEV_FB_API_KEY,
  authDomain: process.env.DEV_FB_AUTH_DOMAIN,
  projectId: process.env.DEV_FB_PROJECT_ID,
  storageBucket: process.env.DEV_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.DEV_FB_MESSAGING_SENDER_ID,
  appId: process.env.DEV_FB_APP_ID,
  measurementId: process.env.DEV_FB_MEASUREMENT_ID,
};
const firebaseConfigProd = {
  apiKey: process.env.PROD_FB_API_KEY,
  authDomain: process.env.PROD_FB_AUTH_DOMAIN,
  projectId: process.env.PROD_FB_PROJECT_ID,
  storageBucket: process.env.PROD_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.PROD_FB_MESSAGING_SENDER_ID,
  appId: process.env.PROD_FB_APP_ID,
  measurementId: process.env.PROD_FB_MEASUREMENT_ID,
};

// Initialize Firebase
const appDev = initializeApp(firebaseConfigDev, 'demmi-dev');
export const authDev = getAuth(appDev);
export const dbDev = getFirestore(appDev);
export const storageDev = getStorage(appDev, 'demmi-dev-vendors');

const appProd = initializeApp(firebaseConfigProd, 'demmi-prod');
export const authProd = getAuth(appProd);
export const dbProd = getFirestore(appProd);
export const storageProd = getStorage(appProd, 'demmi-prod-vendors');

export const getDemmiAuth = () => {
  return store.getState().app.isDevMode ? authDev : authProd;
};
export const getDemmiDB = () => {
  return store.getState().app.isDevMode ? dbDev : dbProd;
};
export const getDemmiStorage = () => {
  return store.getState().app.isDevMode ? storageDev : storageProd;
};
