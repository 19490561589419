import React from 'react';

import NotificationVendorOrderNew from './partials/_notificationVendorOrderNew';
import NotificationVendorRefundRequest from './partials/_notificationVendorRefundRequest';

import NotificationCustomerRefundRequest from './partials/_notificationCustomerRefundRequest';
import NotificationCustomerOrderNew from './partials/_notificationCustomerOrderNew';
import {
  NotificationCustomerWithVendor,
  NotificationVendorWithCustomer,
} from '@models/notifications.model';
import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {
  notificationsVendor: NotificationVendorWithCustomer[];
  notificationsCustomer: NotificationCustomerWithVendor[];
  onNavigate: () => void;
}

const NotificationsPanelContent: React.FC<Props> = ({
  notificationsVendor,
  notificationsCustomer,
  onNavigate,
}) => {
  const CSSBlock = 'notifications-panel-content';

  return (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__title`}>Notifications</div>
      <div className={`${CSSBlock}__content`}>
        {notificationsVendor.map((notification, i) =>
          [
            DemmiFS.NotificationHelper.Admin.NotificationType.ADMIN_REFUND_REQUEST,
            DemmiFS.NotificationHelper.Admin.NotificationType.ADMIN_REFUND_UPDATE,
          ].includes(notification.type) ? (
            <NotificationVendorRefundRequest
              notification={notification}
              onNavigate={onNavigate}
              key={i}
            />
          ) : (
            <NotificationVendorOrderNew
              notification={notification}
              onNavigate={onNavigate}
              key={i}
            />
          ),
        )}
        {notificationsCustomer.map((notification, i) =>
          [
            DemmiFS.NotificationHelper.Admin.NotificationType.ADMIN_REFUND_REQUEST,
            DemmiFS.NotificationHelper.Admin.NotificationType.ADMIN_REFUND_UPDATE,
          ].includes(notification.type) ? (
            <NotificationCustomerRefundRequest
              notification={notification}
              onNavigate={onNavigate}
              key={i}
            />
          ) : (
            <NotificationCustomerOrderNew
              notification={notification}
              onNavigate={onNavigate}
              key={i}
            />
          ),
        )}
      </div>
    </div>
  );
};

export default NotificationsPanelContent;
