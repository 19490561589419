import React, { useEffect, useState } from 'react';

import { updateVendorOpeningHours } from '@core/services/networkService/_vendors';
import DemmiCard from '@demmi-ui/Card/Card';

import VendorShopHours from './_vendorShopHours';
import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {
  CSSBlock: string;
  vendor: DemmiFS.Vendor;
  onUpdated: () => void;
}

const VendorShop: React.FC<Props> = ({ CSSBlock: parentBlock, vendor, onUpdated }) => {
  const CSSBlock = `${parentBlock}-shop`;
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const [hours, setHours] = useState<DemmiFS.VendorOpeningHours>(vendor.shop.openingHours);

  useEffect(() => {
    setIsValid(!!hours);
  }, [hours]);

  const onSave = async () => {
    if (isValid) {
      if (!vendor || !hours) return;

      setIsLoading(true);
      await updateVendorOpeningHours(vendor.docID, hours).then(() => onUpdated());

      setIsLoading(false);
      setEditMode(false);
    }
  };

  const onCancel = () => {
    setHours(vendor.shop.openingHours);
    setEditMode(false);
  };

  return (
    <DemmiCard
      title="Shop"
      isLoading={isLoading}
      editMode={editMode}
      onClickEdit={() => setEditMode(true)}
      onCancel={onCancel}
      onSave={onSave}
      isValid={isValid}
      collapsed>
      <div className={`${CSSBlock}`}>
        <VendorShopHours
          CSSBlock={CSSBlock}
          hours={hours}
          editMode={editMode}
          setHours={setHours}
        />
      </div>
    </DemmiCard>
  );
};

export default VendorShop;
